import { DocumentReference, Timestamp } from "firebase/firestore";
import { ContactField } from "./contact-field";

interface DataPoint {
    weight?: number,
    var?: string,
    max?: number,
    variableMax?: string,
    min?: number,
    descending?: boolean,
    exponential?: number,
    timeDifference?: boolean,
    dataPoints?: DataPoint[],
    type?: string,
}

export const getWarmthScore = (
    contact: any,
    warmthAlgorithm: any,
    userId: string,
) => {
    let scores = new Map();
    if (!contact) {
        scores.set("finalScore", 0);
        return scores;
    }

    const vars = { ...(contact.relationshipData ?? {}), ...(contact.questionsVars ?? {}) };

    // Add extra vars
    vars.homeAddress = contact.addresses?.find((e: any) => e.type === "home");
    vars.personalEmail = contact.emails?.find((e: any) => e.type === "personal");

    const varsCreatedBy = contact.questionsVarsCreatedBy ?? {};
    let finalScore = 0;

    const categories = Object.keys(warmthAlgorithm);
    for (const category of categories) {
        let tmp = recursiveDataPointCalculation({
            dataPoint: warmthAlgorithm[category],
            dataPoints: warmthAlgorithm[category].dataPoints ?? [],
            vars,
            varsCreatedBy: contact.isOrganizationContact ? varsCreatedBy : undefined,
            userId,
            level: 0,
        });

        scores.set(category, tmp);
        finalScore += tmp.get("finalScore");
    }

    if (isNaN(finalScore)) {
        scores.set("finalScore", 0);
        return scores;
    }

    finalScore = Math.ceil(finalScore * 100);
    finalScore = Math.min(Math.max(finalScore, 0), 100);
    scores.set("finalScore", finalScore);
    return scores;
}

const recursiveDataPointCalculation = ({
    dataPoint,
    dataPoints,
    vars,
    varsCreatedBy,
    userId,
    level,
}: {
    dataPoint: DataPoint,
    dataPoints: DataPoint[],
    vars: any,
    varsCreatedBy?: any,
    userId: string,
    level: number,
}): Map<string, any> => {
    let scores = new Map<string, any>();
    scores.set("finalScore", 0)
    let score = 0;

    // Assign weight
    // Use the weight assigned, if there's no weight give equal weights.
    let weight = dataPoint.weight ?? (1 / dataPoints.length);

    // Check for child data points
    if (dataPoint.dataPoints) {
        // Iterate Data Points
        for (const currentDataPoint of dataPoint.dataPoints) {
            let result = recursiveDataPointCalculation({
                dataPoint: currentDataPoint,
                dataPoints: dataPoint.dataPoints,
                vars,
                varsCreatedBy,
                userId,
                level: level++,
            });
            scores.set(currentDataPoint.var, result)
            score += result.get("finalScore")
        }
    } else {
        // Get value
        if (dataPoint.var && vars) {
            if (!vars[dataPoint.var]) {
                return scores;
            }
        } else {
            return scores;
        }
        let value = vars[dataPoint.var];
        scores.set("value", value);

        // Check if is time difference
        if (dataPoint.timeDifference) {
            if (value instanceof Timestamp) {
                value = value.toDate();
            }
            scores.set("value", value);
            value = Math.abs((new Date()).valueOf() - value.valueOf()) / (1000 * 60 * 60 * 24);
        }

        // Check variable max
        if (dataPoint.variableMax) {
            if (vars[dataPoint.variableMax] && typeof vars[dataPoint.variableMax] === "number") {
                dataPoint.max = vars[dataPoint.variableMax];
            }
        }

        // If we have max and min values, normalize the value
        if (typeof dataPoint.max !== 'undefined' && typeof dataPoint.min !== 'undefined') {
            if (typeof dataPoint.max === 'number' && typeof dataPoint.min === 'number') {
                // Check for descending flag
                if (dataPoint.descending) {
                    // If the value increases the score should decrease
                    score = (dataPoint.max - value) / (dataPoint.max - dataPoint.min);
                } else {
                    // If the value increases the score should also increase
                    score = (value - dataPoint.min) / (dataPoint.max - dataPoint.min);
                }

                // Check for exponential number
                if (dataPoint.exponential) {
                    score = Math.exp(dataPoint.exponential * score)
                }
            }
        } else {
            // If normalization is not present it means is a true or false value,
            // do we have the data point or not.
            // Since when trying to get the value the function didn't returned,
            // it means we have it, therefore we change the score to 1.

            if (varsCreatedBy && varsCreatedBy[dataPoint.var]) {
                // It we have 'varsCreatedBy', it means the contact belongs to
                // an organization, check if the user is the creator before 
                // assigning the value.
                if (varsCreatedBy[dataPoint.var] === userId) {
                    if (dataPoint.type && dataPoint.type === "YesNo") {
                        if (value.toLowerCase() === "yes") {
                            score = 1;
                        }
                    } else {
                        score = 1;
                    }
                }
            } else {
                // Personal contact, add the value.
                if (dataPoint.type && dataPoint.type === "YesNo") {
                    if (value.toLowerCase() === "yes") {
                        score = 1;
                    }
                } else {
                    score = 1;
                }
            }
        }
    }

    scores.set("finalScore", score * weight);
    return scores;
}

export function getColorWithGradient(relationshipScore: number, defaultColor?: string): string {
    if (relationshipScore >= 80) {
        return "#FF9E57"; // Very Warm
    } else if (relationshipScore >= 60) {
        // Calculate gradient between Warm and Very Warm
        const percent = (relationshipScore - 60) / 20;
        const warmColor = "#FFD874";
        const veryWarmColor = "#FF9E57";
        return blendColors(warmColor, veryWarmColor, percent);
    } else if (relationshipScore >= 40) {
        // Calculate gradient between Lukewarm and Warm
        const percent = (relationshipScore - 40) / 20;
        const lukewarmColor = "#D2FFE4";
        const warmColor = "#FFD874";
        return blendColors(lukewarmColor, warmColor, percent);
    } else if (relationshipScore >= 20) {
        // Calculate gradient between Cool and Lukewarm
        const percent = (relationshipScore - 20) / 20;
        const coolColor = "#7EBEF9";
        const lukewarmColor = "#D2FFE4";
        return blendColors(coolColor, lukewarmColor, percent);
    } else if (relationshipScore >= 0) {
        // Calculate gradient between Default and Cool
        const percent = relationshipScore / 20;
        const defaultColorValue = defaultColor ?? "#0F3DC7";
        const coolColor = "#7EBEF9";
        return blendColors(defaultColorValue, coolColor, percent);
    } else {
        // Default Color
        return defaultColor ?? "#0F3DC7";
    }
}

function blendColors(color1: string, color2: string, percent: number): string {
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);
    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);

    const r = Math.round(r1 + (r2 - r1) * percent);
    const g = Math.round(g1 + (g2 - g1) * percent);
    const b = Math.round(b1 + (b2 - b1) * percent);

    return `#${(r < 16 ? "0" : "") + r.toString(16)}${(g < 16 ? "0" : "") + g.toString(16)}${(b < 16 ? "0" : "") + b.toString(16)}`;
}

export function convertTimestampsToMilliseconds(obj: any): any {
    // If obj is not an object, return it as is
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    // If obj is an instance of Firestore Timestamp, convert it to milliseconds
    if (obj instanceof Timestamp) {
        return obj.toMillis();
    }

    // Otherwise, recursively traverse the object and convert Timestamps to milliseconds
    if (Array.isArray(obj)) return obj;
    const newObj: any = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key] = convertTimestampsToMilliseconds(obj[key]);
        }
    }
    return newObj;
}

export const sortContacts = (
    contacts: any[],
    warmthAlgorithm: any,
    userId: string,
): any[] => {
    let baseContacts: any[] = [...contacts];
    let finalContacts: any[] = [];

    // -------------------------------Favorites--------------------------------
    finalContacts.push(...baseContacts.filter((contact) => contact.favorite));
    baseContacts = baseContacts.filter((e) => !e.favorite);

    // ----------------------------Profile Picture-----------------------------
    let contactsWithImage = baseContacts.filter((contact) => contact.linkedInProfileData?.profile_pic_url);
    baseContacts = baseContacts.filter((e) => !e.linkedInProfileData?.profile_pic_url);

    // Sort them by warmth score
    contactsWithImage.sort((a, b) =>
        getWarmthScore(b, warmthAlgorithm, userId).get("finalScore")
        - getWarmthScore(a, warmthAlgorithm, userId).get("finalScore")
    );
    finalContacts.push(...contactsWithImage);

    // ------------------------------Warmth Score------------------------------
    let remainingContacts = [...baseContacts];

    // Sort them by warmth score
    remainingContacts.sort((a, b) =>
        getWarmthScore(b, warmthAlgorithm, userId).get("finalScore")
        - getWarmthScore(a, warmthAlgorithm, userId).get("finalScore")
    );
    finalContacts.push(...remainingContacts);

    return finalContacts;
}

export const getPhonesByType = (contact: any, type: string): ContactField => {
    return contact?.phones?.find((phone: ContactField) => phone.type === type);
}