export const getLinkedInUrlProperties = (url: string) => {
    if (url) {
        const linkedInUri = new URL(url);

        if (
            linkedInUri &&
            (linkedInUri.host.includes('linkedin.com') ||
                linkedInUri.pathname.includes('linkedin.com')) &&
            linkedInUri.pathname.split('/').filter(Boolean).length &&
            linkedInUri.pathname.split('/').includes('in')
        ) {
            // Clean Empty Segments
            const segments = linkedInUri.pathname.split('/').filter((e) => e.trim() !== '');

            // Get index of 'in', next path segment should be LinkedIn Id
            const inIndex = segments.indexOf('in');
            let linkedInId;
            if (inIndex !== -1 && segments.length > inIndex + 1) { 
                linkedInId = segments[inIndex + 1];
            }

            return {
                linkedInUri,
                segments,
                linkedInId
            };
        }
    }

    return {
        linkedInUri: undefined,
        segments: undefined,
        linkedInId: undefined,
    };
}
