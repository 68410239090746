import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import ContactsFirestore from "./firestore";

interface MilestonesContextType {
    milestones: any[];
}

export const MilestonesContext = createContext<MilestonesContextType>({
    milestones: [],
});

export const MilestonesContextProvider = ({ contact, children }: { contact: any, children: any }) => {
    // Context
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsFirestore = new ContactsFirestore(user, userProfileData, encryptionService);

    // State
    const [milestones, setMilestones] = useState<any[]>([]);
    const [personalMilestones, setPersonalMilestones] = useState<any[]>([]);
    const [organizationMilestones, setOrganizationMilestones] = useState<any[]>([]);

    useEffect(() => {
        setMilestones([...personalMilestones, ...organizationMilestones]);
    }, [personalMilestones, organizationMilestones]);

    useEffect(() => {
        if (contact?.ref) {
            setPersonalMilestones([]);
            return contactsFirestore.getPersonalMilestonesLive(
                contact,
                async (milestones) => {
                    setPersonalMilestones(milestones);
                },
            );
        }
    }, [isEncryptionInitialized, contact?.ref]);

    useEffect(() => {
        if (contact?.ref && userProfileData?.organization?.id) {
            setOrganizationMilestones([]);
            return contactsFirestore.getOrganizationMilestonesLive(
                contact,
                async (milestones) => {
                    setOrganizationMilestones(milestones);
                },
            );
        }
    }, [contact?.ref, userProfileData?.organization?.id]);

    return (
        <MilestonesContext.Provider
            value={{ milestones }}
        >
            {children}
        </MilestonesContext.Provider>
    );
};