import { useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import NewsCard from "./components/NewsCard";
import { News } from "types/news/news";

// Animation
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { Avatar, Chip, IconButton, OutlinedInput, Stack } from "@mui/material";
import { Done, ExpandLessRounded, ExpandMoreRounded, FilterAltOffOutlined, FilterListRounded, Info, Search } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { NewsContext, NewsContextProvider } from "./services/NewsContext";
import { createUseStyles } from "react-jss";
import NewsFilterDrawer from "./components/NewsFilter";
import { SearchTerm } from "forge/knowledge/schemas/search-term";

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  avatar: {
    backgroundColor: "white",
    color: "black",
    height: "fit-content",
    width: "fit-content",
  },
  chipIcon: { color: "black", width: "0.75em", height: "0.75em", margin: "4px" },
  outlinedInput: {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

function NewsOverview() {
  return (
    <NewsContextProvider>
      <DashboardLayout>
        <MDBox pb={3}>
          <MDBox mb={3}>
            <NewsGrid />
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </NewsContextProvider>
  );
}

const NewsGrid = ({ }: {}) => {
  const classes = styles();

  // Context
  const { newsFiltered, availableSearchTerms, loadingNews } = useContext(NewsContext);

  // State
  const [searchTermsExpanded, setSearchTermsExpanded] = useState<boolean>(false);
  const [activeSearchTerms, setActiveSearchTerms] = useState<SearchTerm[]>([]);
  const [newsStFiltered, setNewsStFiltered] = useState<News[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openNewsFilterDrawer, setOpenNewsFilterDrawer] = useState(false);
  const handleOpenNewsFilterDrawer = () => setOpenNewsFilterDrawer(true);
  const handleCloseNewsFilterDrawer = () => setOpenNewsFilterDrawer(false);

  useEffect(() => {
    setActiveSearchTerms(availableSearchTerms);
  }, [availableSearchTerms]);

  useEffect(() => {
    setNewsStFiltered(newsFiltered.filter(e =>
      e.allSearchTerms.some(term => activeSearchTerms.includes(term))
    ));
  }, [newsFiltered]);

  useEffect(() => {
    setNewsStFiltered(newsFiltered.filter(e =>
      e.allSearchTerms.some(term => activeSearchTerms.includes(term))
    ));
  }, [activeSearchTerms]);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const updatedSearchTerms = availableSearchTerms.filter(term => term.searchTerm.toLowerCase().includes(searchQuery.toLowerCase()));
      setActiveSearchTerms(updatedSearchTerms);
    } else {
      setActiveSearchTerms(availableSearchTerms);
    }
  }, [searchQuery]);

  function renderNews(news: News[]) {
    if (news.length > 0) {
      return news.map((article, index) => (
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <NewsCard article={article} />
        </Grid>
      ));
    } else return [];
  }

  const onChipTapped = (searchTerm: SearchTerm) => {
    if (activeSearchTerms.includes(searchTerm)) {
      const updatedSearchTerms = activeSearchTerms.filter(term => term !== searchTerm);
      setActiveSearchTerms(updatedSearchTerms)
    } else {
      setActiveSearchTerms([...activeSearchTerms, searchTerm])
    }
  }

  const onSeeAllNewsTapped = () => {
    // Set the state with the updated object
    setSearchQuery("");
    setActiveSearchTerms(availableSearchTerms);
  }

  return (<>
    {loadingNews ?
      <div style={{ margin: "auto", marginTop: "32vh", marginBottom: "32vh", display: "block" }}>
        <img
          src={spinningSphere}
          alt="Image 2"
          style={{ margin: "auto", width: "25vh", display: "block" }}
        />
        <MDTypography variant="h5" textAlign="center" fontWeight="medium">Refreshing news feed</MDTypography>
      </div>
      : newsFiltered.length > 0 ?
        <MDBox>
          <NewsFilterDrawer openDrawer={openNewsFilterDrawer} handleCloseDrawer={handleCloseNewsFilterDrawer} />
          <Stack
            direction="row"
            overflow="auto"
            justifyContent="left"
            alignItems="center"
            spacing={0.7}
            style={{ marginBottom: 16 }}
          >
            <OutlinedInput
              id="search-bar"
              className={classes.outlinedInput}
              value={searchQuery}
              onInput={(e) => {
                setSearchQuery((e.target as HTMLInputElement).value);
              }}
              placeholder="Search topics"
              fullWidth={true}
              endAdornment={<Search color="inherit" />}
            />
            <IconButton onClick={handleOpenNewsFilterDrawer}>
              <FilterListRounded fontSize="large" />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            overflow="auto"
            justifyContent="left"
            alignItems="left"
            spacing={0.7}
          >
            <Stack
              flex={1}
              direction="row"
              useFlexGap={searchTermsExpanded}
              flexWrap={searchTermsExpanded ? "wrap" : null}
              overflow="auto"
              justifyContent={searchTermsExpanded ? "center" : null}
              alignItems={searchTermsExpanded ? "center" : null}
              spacing={0.7}
            >
              {availableSearchTerms.map((searchTerm) => {
                const isActive = activeSearchTerms.includes(searchTerm);

                return <Chip
                  label={searchTerm.searchTerm}
                  style={isActive ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white", color: "black", border: "1px solid black" }}
                  onClick={() => onChipTapped(searchTerm)}
                  onDelete={() => onChipTapped(searchTerm)}
                  deleteIcon={isActive ?
                    <Avatar className={classes.avatar}>
                      <Done className={classes.chipIcon} />
                    </Avatar> : <></>}
                />
              })}
            </Stack>
            <IconButton onClick={() => setSearchTermsExpanded(!searchTermsExpanded)}>
              {searchTermsExpanded ? <ExpandLessRounded fontSize="large" /> : <ExpandMoreRounded fontSize="large" />}
            </IconButton>
          </Stack>
          {activeSearchTerms.length != availableSearchTerms.length && <Stack
            mt={"2vh"}
            mb={"1vh"}
            direction="row"
            alignSelf={"center"}
            justifyContent={"center"}
            alignItems="center"
            spacing={0.5}
            style={{ cursor: "pointer" }}
            onClick={onSeeAllNewsTapped}
          >
            <FilterAltOffOutlined style={{ color: "darkgray" }} />
            <MDTypography variant="h5" style={{ color: "darkgray" }}>See all news</MDTypography>
          </Stack>}
          <MDBox height={16} />
          <Grid container spacing={3}>
            {renderNews(newsStFiltered)}
          </Grid>
          {newsStFiltered.length == 0 && <Stack m={"auto"} mt={"36vh"} mb={"36vh"} direction="column" alignItems="center" spacing={0.5}>
            <Info style={{ color: "darkgray" }} />
            <MDTypography variant="h5" style={{ color: "darkgray" }}>No news match your filtering</MDTypography>
          </Stack>}
        </MDBox>
        : <Stack m={"auto"} mt={"36vh"} mb={"36vh"} direction="column" alignItems="center" spacing={0.5}>
          <Info style={{ color: "darkgray" }} />
          <MDTypography variant="h5" style={{ color: "darkgray" }}>No news found</MDTypography>
        </Stack>}
  </>);
}

export default NewsOverview;