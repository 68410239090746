import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

export function Draggable({ id, contact, children }: { id: string, contact: any, children: any }) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: id,
        data: contact
    });

    const style = {
        transform: CSS.Translate.toString(transform),
    }

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {children}
        </div>
    );
}