import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { AttendeeType, ForgeAttendee } from 'types/calendar/forge-attendee';
import { Draggable } from './draggable';
import { Avatar, Chip } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { CloseRounded } from '@mui/icons-material';

export function DraggableChip({
    id,
    attendee,
    onAttendeeRemoved,
}: {
    id?: string,
    attendee: ForgeAttendee,
    onAttendeeRemoved: (attendeeRemoved: any) => void
}) {

    return (
        <Draggable
            id={id ?? attendee?.contact?.id ?? attendee.emailAddress}
            contact={attendee?.contact}
        >
            <Chip
                label={
                    <div style={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: 1,
                    }}>
                        {attendee.name}
                        {attendee.type === AttendeeType.normal && attendee.emailAddress && <MDTypography variant="body2" fontSize="small" style={{ color: "gray", marginLeft: "4px" }}>- {attendee.emailAddress}</MDTypography>}
                    </div>
                }
                style={{ backgroundColor: "white", color: "black", marginTop: 4, border: "1px solid black" }}
                onDelete={() => onAttendeeRemoved(attendee)}
                deleteIcon={
                    <Avatar style={{ background: "black", height: "20px", width: "20px" }}>
                        <CloseRounded style={{ color: "white", height: "16px", width: "16px" }} />
                    </Avatar>
                }
            />
        </Draggable>
    );
}