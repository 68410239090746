import { InternalAxiosRequestConfig } from "axios";
import HttpService from "./http.service";
// import { firebaseAppCheck } from "firebase.init";
import { getToken } from "firebase/app-check";
import { getAuth } from "firebase/auth";

export const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
  const firebaseAuth = getAuth();

  // Request
  const onRequestSuccess = async (config: InternalAxiosRequestConfig<any>) => {
    await firebaseAuth.authStateReady();
    let authToken = await firebaseAuth.currentUser?.getIdToken();
    // let appCheckToken = await getToken(firebaseAppCheck);

    config.headers.Authorization = authToken;
    // config.headers["X-Firebase-AppCheck"] = appCheckToken.token;
    return config;
  };
  const onRequestFail = (error: any) => Promise.reject(error);

  HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);


  // Response
  const onResponseSuccess = (response: any) => {
    const appVersion = process.env.REACT_APP_VERSION;
    if (response.headers['x-version'] > appVersion) { // if server version newer
      window.localStorage.setItem('version-update-needed', 'true'); // set version update item so can refresh app later
      window.dispatchEvent(new Event("version-update-needed"));
    };

    return response;
  };

  const onResponseFail = (error: any) => {
    const status = error.status || error.response?.status;
    if (status === 401) {
      onUnauthenticated();
    }

    return Promise.reject(error);
  };
  HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};