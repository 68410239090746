/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement, useContext, Fragment, useLayoutEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";
import crudRoutes from "crud.routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, AuthContext } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { setupAxiosInterceptors } from "forge/core/services/interceptor";
import Login from "forge/auth/login";
import ProtectedRoute from "forge/auth/components/ProtectedRoute";
import { Helmet } from "react-helmet-async";
import { TableProvider } from "forge/core/services/TableContext";
import CDAKInput from "forge/auth/cdak";
import NetworkStatusIndicator from "forge/core/components/NetworkStatusIndicator";
import { Button, Snackbar } from "@mui/material";
import { AbilityContext } from "forge/organization/rbac/CanContext";
import defineAbilityFor from "forge/organization/rbac/ability";
import { OrganizationContext } from "forge/organization/services/OrganizationContext";
import { useAbility } from "@casl/react";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [versionUpdateSnackbar, setVersionUpdateSnackbar] = useState<boolean>(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { state, pathname } = useLocation();

  // Context
  const { getCurrentUser } = useContext(AuthContext);
  const { member } = useContext(OrganizationContext);
  const { user, userProfileData } = getCurrentUser();

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter && miniSidenav) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);



  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const navigatorButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.12rem"
      height="3.12rem"
      bgColor="black"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      left="1rem"
      top="1rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleMiniSidenav}
    >
      <Icon fontSize="small" color="inherit">
        menu_open
      </Icon>
    </MDBox>
  );

  useLayoutEffect(() => {
    // if there is an update available and no state passed to route
    if (!state && window.localStorage.getItem('version-update-needed')) {
      window.localStorage.removeItem('version-update-needed'); // remove the storage object
      window.location.reload(); // refresh the browser
    }
  }, [pathname]);

  window.addEventListener('version-update-needed', () => {
    console.log("Change to local storage!");
    if (window.localStorage.getItem('version-update-needed')) {
      setVersionUpdateSnackbar(true);
    }
  })

  const handleRefresh = () => {
    window.localStorage.removeItem('version-update-needed'); // remove the storage object
    window.location.reload(); // refresh the browser
  };

  const action = (
    <Fragment>
      <Button color="primary" size="small" onClick={handleRefresh}>
        REFRESH
      </Button>
    </Fragment>
  );

  return <AbilityContext.Provider
    value={defineAbilityFor(userProfileData?.doesUserBelongsToAnOrganization, member?.role, user?.uid)}
  >
    <TableProvider>
      <Helmet>
        {process.env.REACT_APP_STAGING !== "true" && <meta
          httpEquiv="Content-Security-Policy"
          content={`
          default-src 'self' ws: *.googleapis.com *.cloudfunctions.net *.gstatic.com *.google.com *.firebaseapp.com *.forgeyourcrm.com *.forgeyournetwork.com *.virgilsecurity.com *.seald.io *.sentry.io *.wikipedia.org api.nango.dev ${process.env.REACT_APP_DEV === "true" ? "localhost:* 0.0.0.0:*" : ""};
          script-src 'wasm-unsafe-eval' *.googleapis.com *.cloudfunctions.net *.gstatic.com *.google.com *.firebaseapp.com *.sentry.io ${process.env.REACT_APP_DEV === "true" ? "localhost:* 0.0.0.0:*" : ""};
          style-src 'self' 'unsafe-inline' *.googleapis.com *.forgeyourcrm.com *.forgeyournetwork.com;
          img-src 'self' * data:;
          media-src 'self';
          `}
        ></meta>}
        {process.env.REACT_APP_DEV === "true"
          ? undefined
          : <meta
            httpEquiv="Strict-Transport-Security"
            content={`max-age=31536000; includeSubDomains`}
          ></meta>}
      </Helmet>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={versionUpdateSnackbar}
        message="A new version of Forge is available"
        key={'version-update-needed'}
        action={action}
      />
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <NetworkStatusIndicator />
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard PRO"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator /> */}
            {window.innerWidth < 1200 && navigatorButton}
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Router />
      </ThemeProvider>
    </TableProvider>
  </AbilityContext.Provider>;
}

function Router() {
  // Context
  const ability = useAbility(AbilityContext);

  const getRoutes = (allRoutes: any[]): any => {
    return allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        name: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          if (route.name && !ability.can("use", route.name.toLowerCase())) {
            return null;
          }

          return <Route
            path={route.route}
            element={<ProtectedRoute>
              {route.component}
            </ProtectedRoute>}
            key={route.key} />;
        }

        return null;
      }
    );
  };

  const getCrudRoutes = (allRoutes: any[]): any =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getCrudRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            path={route.route}
            element={
              <ProtectedRoute>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return <Routes>
    <Route path="/auth/login" element={<Login />} />
    <Route path="/auth/cdak" element={<CDAKInput />} />
    {getRoutes(routes)}
    {getCrudRoutes(crudRoutes)}
    <Route path="*" element={<Navigate to="/home" />} />
  </Routes>;
}