import { Card, Divider, IconButton, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Add, ArrowForwardIos, TaskAlt } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { CommitmentsContext } from "../../../../commitments/services/CommitmentsContext";
import { Commitment } from "types/commitment";
import CommitmentCard from "forge/commitments/components/CommitmentCard";

const options = { year: 'numeric', month: 'short', day: '2-digit' };

function ContactCommitmentsCard({ contact, handleOpenDrawer }: { contact: any, handleOpenDrawer: () => void }) {
    const { commitments } = useContext(CommitmentsContext);
    const [finalCommitments, setFinalCommitments] = useState<Commitment[]>([]);

    useEffect(() => {
        setFinalCommitments(commitments);
    }, [commitments]);

    return (
        <Card sx={{ height: "100%" }}>
            <MDBox m={3}>
                <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ justifyContent: "space-between" }}
                >
                    <MDTypography variant="h5">Commitments</MDTypography>
                    {finalCommitments.length > 0 && <MDBox
                        width="3rem"
                        height="3rem"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        style={{ cursor: "pointer", background: "cornflowerblue" }}
                        onClick={handleOpenDrawer}
                    >
                        <IconButton
                            aria-label="add-knowledge"
                            style={{ padding: 0 }}
                            onClick={handleOpenDrawer}
                        >
                            <TaskAlt style={{ color: "white" }} />
                            <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-12px", right: "-6px" }} />
                        </IconButton>
                    </MDBox>}
                </MDBox>
                <Divider style={{ opacity: 1 }} />
                {finalCommitments.length > 0 ?
                    finalCommitments.map((commitment: Commitment) => (
                        <CommitmentCard
                            commitment={commitment}
                            showAvatar={false}
                        />
                    )) : (
                        <Stack mt={5} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
                            <MDTypography variant="body2" align="center" style={{ color: "darkgray" }}>{`What have you promised\nto do for ${contact?.firstName}?`}</MDTypography>
                            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
                            <MDBox
                                width="3rem"
                                height="3rem"
                                borderRadius="md"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                shadow="md"
                                style={{ cursor: "pointer", background: "cornflowerblue" }}
                                onClick={handleOpenDrawer}
                            >
                                <IconButton
                                    aria-label="add-knowledge"
                                    style={{ padding: 0 }}
                                    onClick={handleOpenDrawer}
                                >
                                    <TaskAlt style={{ color: "white" }} />
                                    <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-12px", right: "-6px" }} />
                                </IconButton>
                            </MDBox>
                        </Stack>
                    )}
            </MDBox>
        </Card>
    );
};

export default ContactCommitmentsCard;