import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import HttpService from "../../../core/services/http.service";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";
import { ForgeEncryption } from "forge/core/services/encryption";
import { getLinkedInUrlProperties } from "../utils";

class ContactsApi {
  private user: User;
  private userProfileData: UserProfileData;
  private forgeEncryption: ForgeEncryption;

  constructor(user: User, userProfileData: UserProfileData, forgeEncryption: ForgeEncryption) {
    this.user = user;
    this.userProfileData = userProfileData;
    this.forgeEncryption = forgeEncryption;
  }

  createContact = async (
    contact: any,
    isOrganizationContact: boolean = false
  ): Promise<any> => {
    const endpoint = `contacts`;
    delete contact.ref;
    return await HttpService.post(
      endpoint,
      { contact, isTeamContact: isOrganizationContact }
    );
  };

  updateRelationshipData = async (contact: any) => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }

    const callable = httpsCallable(functions, "logs-updateRelationshipDataOnDemand");
    await callable({ "contactId": contact.ref?.id });
    return;
  };

  updateContact = async (contact: any): Promise<boolean> => {
    if (contact?.ref) {
      const endpoint = `contacts/${contact.ref.id}`;
      delete contact.ref;
      return await HttpService.patch(endpoint, { contact });
    }

    return false;
  };

  mergeContact = async (mainContact: any, contactsToMerge: any[]): Promise<boolean> => {
    if (mainContact?.ref) {
      let contactIds = contactsToMerge.filter((contact) => contact.ref?.id !== null);
      contactIds = contactIds.map((contact) => contact.ref.id);

      const endpoint = `contacts/merge/${mainContact.ref.id}?contacts=${contactIds.join(",")}`;
      delete mainContact.ref;
      return await HttpService.patch(endpoint, { mainContact });
    }

    return false;
  };

  deleteContact = async (contact: any): Promise<boolean> => {
    if (contact?.ref) {
      const endpoint = `contacts/${contact.ref.id}`;
      return await HttpService.delete(endpoint);
    }

    return false;
  };

  searchLinkedInData = async (
    contact: any,
    option?: LinkedInProfileOption,
    url?: string,
  ): Promise<void> => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }
    const callable = httpsCallable(functions, "contactData-searchLinkedInData");

    try {
      const { linkedInId } = getLinkedInUrlProperties(option?.link ?? url);
      if (linkedInId) {
        await callable({
          linkedInId,
          contactId: contact.ref?.id,
          linkedInProfilePictureUrl: option?.profilePicture,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  searchLinkedInProfiles = async (contact: any): Promise<void> => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }
    const callable = httpsCallable(functions, "contactData-searchLinkedInProfiles");

    try {
      await callable({
        'contactRef': contact.ref?.path,
        'firstName': contact.firstName,
        'lastName': contact.lastName,
        'company': contact.company,
      });
    } catch (error) {
      console.error(error);
    }
  };

  refreshLinkedInData = async (linkedInUrl: string, contact: any): Promise<void> => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }
    const callable = httpsCallable(functions, "contactData-refreshLinkedInData");

    try {
      const { linkedInId } = getLinkedInUrlProperties(linkedInUrl);
      if (linkedInId) {
        await callable({
          linkedInId,
          'contactId': contact?.ref?.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getCompanyFromDomain = async (
    domain: string,
    email?: string,
  ): Promise<any> => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }

    const callable = httpsCallable(functions, "contactData-getCompanyFromDomain");
    const response = await callable({ domain, email });

    return response.data;
  };

  searchCrmContact = async (contact: any): Promise<any> => {
    const functions = getFunctions();
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001);
    }

    try {
      const callable = httpsCallable(functions, "pipeline-searchcrmcontact");
      const response = await callable({
        "contact": {
          "firstName": contact.firstName,
          "lastName": contact.lastName,
          "emailStrings": contact.emailStrings,
          "phoneStrings": contact.phoneStrings,
        },
        "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
      });

      return response.data;
    } catch (error) {
      console.warn(error);
    }

    return;
  };
}

export default ContactsApi;