import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import { Company } from "types/company";
import { AppStateContext } from "forge/core/services/AppStateContext";
import { SplashScreen } from "forge/core/components/SplashScreen";
import ContactsFirestore from "./firestore";

export const ContactsContext = createContext({
    contacts: [],
    contactsMap: new Map(),
    getContact: (contactId: string): any => null,
    getContacts: (contactIds: string[]): any[] => [],
    getContactsCompanies: (): Company[] => [],
    getActiveSearchTerms: (): { [key: string]: string[] } => ({}),
    getPossibleUserContacts: (): any[] => [],
});

export const ContactsContextProvider = ({ children }: { children: any }) => {
    // Context
    const { contactsLoading, setContactsLoading } = useContext(AppStateContext);
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);

    // State
    const [contacts, setContacts] = useState([]);
    const [contactsMap, setContactsMap] = useState<Map<string, any>>(new Map());
    const [personalContacts, setPersonalContacts] = useState([]);
    const [personalContactsMap, setPersonalContactsMap] = useState<Map<string, any>>(new Map());
    const [organizationContacts, setOrganizationContacts] = useState([]);
    const [organizationContactsMap, setOrganizationContactsMap] = useState<Map<string, any>>(new Map());

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsFirestore = new ContactsFirestore(user, userProfileData, encryptionService);

    useEffect(() => {
        setContacts([...personalContacts, ...organizationContacts]);
    }, [personalContacts, organizationContacts]);

    useEffect(() => {
        setContactsMap(new Map([...personalContactsMap, ...organizationContactsMap]));
    }, [personalContactsMap, organizationContactsMap]);

    useEffect(() => {
        if (user) {
            return contactsFirestore.getPersonalContactsLive((contacts) => {
                const contactsMapped = contacts.reduce<Map<string, any>>((acc, currentValue, currentIndex, __) => {
                    acc.set(currentValue.id, currentValue);
                    return acc;
                }, new Map());

                setPersonalContactsMap(contactsMapped);
                setPersonalContacts(contacts);
                setContactsLoading(false);
            });
        } else {
            setContactsLoading(false);
        }
    }, [isEncryptionInitialized]);

    useEffect(() => {
        if (userProfileData?.organization?.id) {
            return contactsFirestore.getOrganizationContactsLive((contacts) => {
                const contactsMapped = contacts.reduce<Map<string, any>>((acc, currentValue, currentIndex, __) => {
                    acc.set(currentValue.id, currentValue);
                    return acc;
                }, new Map());

                setOrganizationContactsMap(contactsMapped);
                setOrganizationContacts(contacts);
                // setContactsLoading(false);
            });
        }
        // else {
        //     setContactsLoading(false);
        // }
    }, [userProfileData?.organization?.id]);

    const getContact = (contactId: string): any => {
        return contactsMap.get(contactId);
    };

    const getContacts = (contactIds: string[]): any[] => {
        let contacts = [];

        for (const contactId of contactIds) {
            contacts.push(contactsMap.get(contactId));
        }

        return contacts.filter((e) => e);
    };

    const getContactsCompanies = (): Company[] => {
        // Filtering out forged contacts with non-empty experiences and mapping them to Company objects
        const companies = contacts
            .filter(e => e.linkedInProfileData?.experiences != null &&
                e.linkedInProfileData.experiences.length > 0)
            .map(e => ({
                id: '',
                name: e.linkedInProfileData.experiences[0].company || '', // Assuming the first experience is always present
                imageUrl: e.linkedInProfileData.experiences[0].logoUrl
            }));

        // Removing duplicates based on company name
        const seen = new Set();
        const uniqueCompanies = companies.filter(company => {
            const isDuplicate = seen.has(company.name);
            seen.add(company.name);
            return !isDuplicate;
        });

        // Sorting the list alphabetically by company name
        uniqueCompanies.sort((a, b) => a.name.localeCompare(b.name));


        return uniqueCompanies;
    };

    const getActiveSearchTerms = (): { [key: string]: string[] } => {
        const termsMap: { contactRef: string | null; searchTerms: string[] }[] = contacts
            .map(e => ({
                contactRef: e.ref?.id,
                searchTerms: e.searchTermsInUse,
            }));

        const searchTerms: { [key: string]: string[] } = {};
        for (const term of termsMap) {
            if (term.contactRef && term.searchTerms && term.searchTerms.length > 0) {
                for (const finalTerm of term.searchTerms) {
                    if (searchTerms[finalTerm]) {
                        searchTerms[finalTerm].push(term.contactRef!);
                    } else {
                        searchTerms[finalTerm] = [term.contactRef!];
                    }
                }
            }
        }

        return searchTerms;
    }

    const getPossibleUserContacts = (): any[] => {
        const { user, userProfileData } = getCurrentUser();

        const meContact = personalContactsMap.get(user.uid);
        if (meContact) {
            return [meContact];
        }

        return personalContacts.filter((e) =>
            e.emailStrings?.some((email: string) => userProfileData?.emails?.some((userEmail) => userEmail.email === email))
        );
    }

    if (contactsLoading) {
        return <SplashScreen />
    }

    return (
        <ContactsContext.Provider
            value={{
                contacts,
                contactsMap,
                getContact,
                getContacts,
                getContactsCompanies,
                getActiveSearchTerms,
                getPossibleUserContacts
            }}
        >
            {children}
        </ContactsContext.Provider>
    );
};