import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { AuthContext, ContactsContext } from "context";
import theme from "assets/theme";

// MUI
import {
    Backdrop,
    Card,
    Divider,
    Drawer,
    FormControl,
    Icon,
    IconButton,
    Stack,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { ContactMail, ManageSearch, Refresh, SyncDisabled } from "@mui/icons-material";
import ContactsApi from "../../services/api";
import EditContactMultiField from "../EditContactMultiField";
import { ContactField } from "types/contact/contact-field";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import appleLogo from "assets/forge/svgs/apple.svg";
import appleWhiteLogo from "assets/forge/svgs/apple-white.svg";
import { ContactContext } from "../../services/ContactContext";
import { CrmExtended } from "types/pipeline/crm";
import MDSnackbar from "components/MDSnackbar";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";


const styles = createUseStyles({
    formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
    formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        );
    },
);

function EditContactDrawer({ openDrawer, handleCloseDrawer, contact }: { openDrawer: boolean, handleCloseDrawer: () => void, contact: any }) {
    // UI
    const classes = styles();
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { crm, crmContact, refreshLinkedInData, searchLinkedInProfiles } = useContext(ContactContext);
    const { contacts, getContact, getContactsCompanies } = useContext(ContactsContext);

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // State
    const [loading, setLoading] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [openDialogDiscard, setOpenDialogDiscard] = useState(false);
    const handleOpenDialogDiscard = () => setOpenDialogDiscard(true);

    const [title, setTitle] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [role, setRole] = useState<string>();
    const [linkedIn, setLinkedIn] = useState<string>();
    const [birthday, setBirthday] = useState<Dayjs>();
    const [emails, setEmails] = useState<ContactField[]>();
    const [phones, setPhones] = useState<ContactField[]>();
    const [addresses, setAddresses] = useState<ContactField[]>();

    // Track initial state values
    const initialState = useRef<{ [key: string]: any }>({
        title,
        firstName,
        lastName,
        company,
        jobTitle: role,
        linkedInUrl: linkedIn,
        birthday: undefined,
        emails: emails ?? [],
        phones: phones ?? [],
        addresses: addresses ?? [],
    });

    useEffect(() => {
        if (!contact) return;

        setTitle(contact.title);
        setFirstName(contact.firstName);
        setLastName(contact.lastName);
        setCompany(contact.company);
        setRole(contact.jobTitle);
        setLinkedIn(contact.linkedInUrl);

        let tmpBirthday = dayjs(new Date(contact.birthdayYear, contact.birthdayMonth - 1, contact.birthdayDay));
        if (contact.birthdayDay && contact.birthdayMonth && contact.birthdayYear) {
            setBirthday(tmpBirthday);
        }

        setEmails(contact.emails ?? []);
        setPhones(contact.phones ?? []);
        setAddresses(contact.addresses ?? []);

        initialState.current = {
            title: contact.title,
            firstName: contact.firstName,
            lastName: contact.lastName,
            company: contact.company,
            jobTitle: contact.jobTitle,
            linkedInUrl: contact.linkedInUrl,
            birthday: tmpBirthday.isValid() ? tmpBirthday : undefined,
            emails: contact.emails ?? [],
            phones: contact.phones ?? [],
            addresses: contact.addresses ?? [],
        };
    }, [contact]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        try {
            let fullName: string = '';
            fullName += firstName;
            fullName += ' ';
            fullName += lastName ?? '';

            let updatedContact: any = {
                ref: contact.ref,
                title,
                name: fullName.trim(),
                firstName,
                lastName,
                company,
                jobTitle: role,
                linkedInUrl: linkedIn,
                birthdayDay: birthday?.toDate().getDate(),
                birthdayMonth: birthday?.toDate().getMonth() + 1,
                birthdayYear: birthday?.toDate().getFullYear(),
                emails: emails.map((e) => e.toMap()),
                phones: phones.map((e) => e.toMap()),
                addresses: addresses.map((e) => e.toMap()),
            };

            await contactsApi.updateContact(updatedContact);
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        handleCloseDrawer();
    };

    const onEmailsEdited = (values: ContactField[]) => {
        setEmails(values);
    }

    const onPhonesEdited = (values: ContactField[]) => {
        setPhones(values);
    }

    const onAddressesEdited = (values: ContactField[]) => {
        setAddresses(values);
    }

    const capitalizeWords = (value: string) => {
        return value
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(' ');
    };

    const handleLinkedInRefresh = () => {
        if (linkedIn.trim() && linkedIn.trim() == contact.linkedInUrl?.trim()) {
            setShowSnackbar(true);
            setSnackbarMessage(
                `We're refreshing ${contact.firstName}'s LinkedIn Profile, you can see the progress in Research.`,
            );
            refreshLinkedInData(linkedIn.trim());
        } else {
            setShowSnackbar(true);
            setSnackbarMessage(
                `We're refreshing ${contact.firstName}'s LinkedIn Profile options, you can see the progress in Research.`,
            );
            searchLinkedInProfiles();
        }
    }

    const handleCloseDialogDiscard = async (result: boolean) => {
        setOpenDialogDiscard(false);
        if (result) {
            handleCloseDrawer();
        }
    };

    const handleClose = () => {
        const currentState: { [key: string]: any } = {
            title,
            firstName,
            lastName,
            company,
            jobTitle: role,
            linkedInUrl: linkedIn,
            birthday: birthday,
            emails: emails.map((e) => e.toMap()),
            phones: phones.map((e) => e.toMap()),
            addresses: addresses.map((e) => e.toMap()),
        };

        // Compare current state with initial state
        const hasChanged = Object.keys(currentState).some(
            key => {
                if (dayjs.isDayjs(currentState[key]) && dayjs.isDayjs(initialState.current[key])) {
                    return !currentState[key].isSame(initialState.current[key]);
                }

                if (Array.isArray(currentState[key]) && Array.isArray(initialState.current[key])) {
                    if (currentState[key].length !== initialState.current[key].length) {
                        return true;
                    }

                    return currentState[key].some((value: any, index: number) => value !== initialState.current[key][index]);
                }

                console.log(key, `${currentState[key]} !== ${initialState.current[key]}`, "=", currentState[key] !== initialState.current[key]);
                return currentState[key] !== initialState.current[key];
            }
        );

        if (hasChanged && !loading) {
            console.log('State has changed');
            handleOpenDialogDiscard();
        } else {
            handleCloseDrawer();
        }
    }

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    height: "fit-content",
                    maxHeight: "96%",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            <ConfirmationDialog
                openDialog={openDialogDiscard}
                handleCloseDialog={handleCloseDialogDiscard}
                title="Are you sure you want to discard your changes?"
                description=""
                confirmText="Discard"
                denyVariant="outlined"
            />
            <MDSnackbar
                color="info"
                icon="info"
                title="LinkedIn Sync"
                content={snackbarMessage}
                dateTime=''
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                close={() => setShowSnackbar(false)}
                bgWhite
            />
            <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
                <Backdrop
                    sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                    open={loading}
                >
                    <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
                </Backdrop>

                {/* Header */}
                < MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                    pt={4}
                    pb={0.5}
                    px={3}
                >
                    <MDBox>
                        <MDTypography variant="h5">Edit Contact</MDTypography>
                    </MDBox>
                    <Icon
                        sx={({ typography: { size }, palette: { dark, white } }) => ({
                            fontSize: `${size.lg} !important`,
                            color: dark.main,
                            stroke: "currentColor",
                            strokeWidth: "2px",
                            cursor: "pointer",
                            transform: "translateY(5px)",
                        })}
                        onClick={handleClose}
                    >
                        close
                    </Icon>
                </MDBox >
                <Divider />

                {/* Body */}

                <MDBox style={{ width: "100%", flex: 1 }} mb={3} px={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="column" spacing={1}>
                            <MDBox className={classes.formTile}>
                                <ManageSearch className={classes.formIcon} />
                                <MDTypography variant="h6">External record being synced</MDTypography>
                            </MDBox>
                            {crmContact ?
                                <Card
                                    elevation={2}
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        padding: 16,
                                        cursor: "pointer",
                                        background: "black"
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" style={{ "width": "100%" }}>
                                        <img src={CrmExtended.getIcon(crm)} style={{ height: "30px" }} alt={CrmExtended.getName(crm)} />
                                        <MDTypography
                                            variant="body2"
                                            fontSize="large"
                                            fontWeight="medium"
                                            style={{ color: "white" }}
                                        >
                                            {crmContact.name}
                                        </MDTypography>
                                        <IconButton>
                                            <SyncDisabled style={{ color: "white" }} />
                                        </IconButton>
                                    </Stack>
                                </Card>
                                : contact?.identifier ?
                                    <Card
                                        elevation={2}
                                        style={{
                                            flex: 1,
                                            alignItems: "center",
                                            padding: 16,
                                            cursor: "pointer",
                                            background: "black"
                                        }}
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" style={{ "width": "100%" }}>
                                            <img src={appleWhiteLogo} style={{ height: "30px" }} alt="Apple" />
                                            <MDTypography
                                                variant="body2"
                                                fontSize="large"
                                                fontWeight="medium"
                                                style={{ color: "white" }}
                                            >
                                                {contact.name}
                                            </MDTypography>
                                            <IconButton>
                                            </IconButton>
                                        </Stack>
                                    </Card>
                                    : <Stack direction="row" spacing={1}>
                                        <Card elevation={2} style={{ flex: 1, alignItems: "center", padding: 16, cursor: "pointer" }}>
                                            <img src={appleLogo} style={{ height: "30px" }} alt="Apple" />
                                            <MDTypography variant="body2" fontSize="large" fontWeight="normal">Apple</MDTypography>
                                        </Card>
                                        {crm && <Card elevation={2} style={{ flex: 1, alignItems: "center", padding: 16, cursor: "pointer" }}>
                                            <img src={CrmExtended.getIcon(crm)} style={{ height: "30px" }} alt={CrmExtended.getName(crm)} />
                                            <MDTypography variant="body2" fontSize="large" fontWeight="normal">{CrmExtended.getName(crm)}</MDTypography>
                                        </Card>}
                                    </Stack>}
                            <MDBox className={classes.formTile}>
                                <ContactMail className={classes.formIcon} />
                                <MDTypography variant="h6">Contact Info</MDTypography>
                            </MDBox>
                            <TextField
                                id="title"
                                label="Prefix/Title"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                InputLabelProps={{ style: { color: 'gray' } }}
                            />
                            <TextField
                                id="firstName"
                                label="First Name"
                                variant="outlined"
                                style={{ width: "100%", marginTop: "16px" }}
                                value={firstName}
                                onChange={(e) => setFirstName(capitalizeWords(e.target.value))}
                                InputLabelProps={{ style: { color: 'gray' } }}
                            />
                            <TextField
                                id="lastName"
                                label="Last Name"
                                variant="outlined"
                                style={{ width: "100%", marginTop: "16px" }}
                                value={lastName}
                                onChange={(e) => setLastName(capitalizeWords(e.target.value))}
                                InputLabelProps={{ style: { color: 'gray' } }}
                            />
                            <TextField
                                id="company"
                                label="Organization"
                                variant="outlined"
                                style={{ width: "100%", marginTop: "16px" }}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                InputLabelProps={{ style: { color: 'gray' } }}
                            />
                            <TextField
                                id="role"
                                label="Role"
                                variant="outlined"
                                style={{ width: "100%", marginTop: "16px" }}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                InputLabelProps={{ style: { color: 'gray' } }}
                            />
                            <Stack direction="row" alignItems="center">
                                <TextField
                                    id="linkedIn"
                                    label="LinkedIn"
                                    variant="outlined"
                                    style={{ width: "100%", marginTop: "16px", marginBottom: "8px" }}
                                    value={linkedIn}
                                    onChange={(e) => setLinkedIn(e.target.value)}
                                    InputLabelProps={{ style: { color: 'gray' } }}
                                />
                                <Refresh
                                    onClick={handleLinkedInRefresh}
                                    fontSize="medium"
                                    style={{
                                        marginLeft: 8,
                                        marginTop: 2,
                                    }}
                                />
                            </Stack>
                            <DatePicker
                                label="Birthday"
                                format="MMM DD, YYYY"
                                value={birthday}
                                onChange={setBirthday}
                            />
                        </Stack>
                        <Divider />
                        <EditContactMultiField
                            onEdited={onEmailsEdited}
                            existingValues={contact?.emails?.map((e: any) => ContactField.fromMap(e)) ?? []}
                            fieldType={{
                                type: "email",
                                addText: "Add an email address",
                                hint: "Email",
                                defaultOption: "work",
                                options: ["personal", "work", "school", "other"],
                                inputType: "email",
                            }}
                        />
                        <Divider />
                        <EditContactMultiField
                            onEdited={onPhonesEdited}
                            existingValues={contact?.phones?.map((e: any) => ContactField.fromMap(e)) ?? []}
                            fieldType={{
                                type: "phone",
                                addText: "Add a phone number",
                                hint: "Phone",
                                defaultOption: "work",
                                options: ["mobile", "home", "work", "other"],
                                inputType: "tel",
                            }}
                        />
                        <Divider />
                        <EditContactMultiField
                            onEdited={onAddressesEdited}
                            existingValues={contact?.addresses?.map((e: any) => ContactField.fromMap(e)) ?? []}
                            fieldType={{
                                type: "address",
                                addText: "Add an address",
                                hint: "Address",
                                defaultOption: "work",
                                options: ["home", "work", "other"],
                            }}
                        />
                    </LocalizationProvider>
                </MDBox>

                {/* Footer */}
                <Stack py={2} px={3}
                    direction="row"
                    justifyContent={"space-between"}
                    spacing={0.5}
                    style={{
                        borderTop: "1px solid",
                        borderColor: "lightgray",
                    }}>
                    <MDBox />
                    <MDButton variant="gradient" color="info" type="submit">Save</MDButton>
                </Stack>
            </FormControl>
        </Drawer >
    );
}

// Setting default values for the props of EditContactDrawer
EditContactDrawer.defaultProps = {
    handleCloseDrawer: null,
};

export default EditContactDrawer;