import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "assets/theme";

// @mui material components
import { Tooltip, Avatar, Box, Drawer, useMediaQuery, Card, Stack, IconButton, Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

import CreateOpportunity from "./components/CreateOpportunity";
import { ApartmentRounded, Person, QuestionMark, Sync, Verified } from "@mui/icons-material";
import { OpportunitiesContext, OpportunitiesContextProvider } from "./services/OpportunitiesContext";
import { ForgeOpportunity } from "types/pipeline/opportunity";
import { AuthContext, ContactsContext } from "context";
import { getColorWithGradient, getWarmthScore } from "types/contact/contact";
import { smartTime } from "forge/core/utilities";
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar";
import { Pie } from "react-chartjs-2";
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext";
import ContactWarmthDialog from "forge/people/contacts/components/ContactWarmthDialog";
import { CrmExtended } from "types/pipeline/crm";
import { CrmContext } from "forge/settings/services/CrmContext";

function OpportunitiesManagement(): JSX.Element {
    // Context
    const { opportunities, showClosed, setShowClosed, getOpportunities } = useContext(OpportunitiesContext);
    const { pipelineConfig } = useContext(CrmContext);

    // State
    const [openCreateOpportunityDrawer, setOpenCreateOpportunityDrawer] = useState(false);

    const handleOpenDrawer = () => setOpenCreateOpportunityDrawer(true);
    const handleCloseDrawer = () => setOpenCreateOpportunityDrawer(false);

    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <DashboardLayout>
            <Drawer
                anchor="right"
                open={openCreateOpportunityDrawer}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: {
                        width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                    },
                }}
            >
                <CreateOpportunity handleCloseDrawer={handleCloseDrawer} />
            </Drawer>
            <MDBox pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <MDBox
                            p={3}
                            lineHeight={1}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Stack direction="column" spacing={0.5}>
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Opportunities
                                    </MDTypography>
                                </Stack>
                                {pipelineConfig?.isEnabled && pipelineConfig?.integration && <Stack direction="row" spacing={1} alignItems="center">
                                    <img src={CrmExtended.getIcon(pipelineConfig.integration)} style={{ height: "30px" }} alt={CrmExtended.getName(pipelineConfig.integration)} />
                                    <MDTypography
                                        variant="body2"
                                        fontWeight="medium"
                                    >
                                        {CrmExtended.getName(pipelineConfig.integration)}
                                    </MDTypography>
                                    <Verified style={{ color: "green" }} />
                                    {opportunities.length > 0 && <MDTypography
                                        variant="body2"
                                        style={{ color: "green", fontWeight: "500" }}
                                    >
                                        Last sync {smartTime(opportunities[0].updatedAt, true)}
                                    </MDTypography>}
                                    <IconButton
                                        onClick={async () => {
                                            getOpportunities();
                                        }}
                                    >
                                        <Sync />
                                    </IconButton>
                                </Stack>}
                                <LastUpdated />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <MDButton
                                    variant="outlined"
                                    color="dark"
                                    size="small"
                                    type="submit"
                                    style={{ textTransform: "none", height: "40px" }}
                                    onClick={() => setShowClosed(!showClosed)}
                                >
                                    <Icon
                                        style={{
                                            fontSize: "1.2rem",
                                            color: showClosed ? "black" : "lightgrey"
                                        }}
                                    >
                                        disabled_visible
                                    </Icon>
                                    &nbsp;
                                    {showClosed ? "Hide" : "Show"} Closed Deals
                                </MDButton>
                                <MDButton
                                    variant="gradient"
                                    color="dark"
                                    size="small"
                                    type="submit"
                                    style={{ textTransform: "none", height: "40px" }}
                                    onClick={handleOpenDrawer}
                                >
                                    + Add Opportunity
                                </MDButton>
                            </Stack>
                        </MDBox>
                        <OpportunitiesTable />
                    </Card>
                </MDBox>
            </MDBox >
            <Footer />
        </DashboardLayout >
    );
}

const LastUpdated = ({ }: {}) => {
    // Context
    const { opportunities } = useContext(OpportunitiesContext);

    return (
        opportunities.length > 0 ?
            <MDTypography variant="body2" fontWeight="normal" fontSize="small">
                {`Last activity: ${smartTime(opportunities[0].updatedAt)}`}
            </MDTypography> : <></>
    );
};

const OpportunitiesTable = ({ }: {}) => {
    // Context
    const { warmthAlgorithm } = useContext(RemoteConfigContext);
    const { opportunities, showClosed } = useContext(OpportunitiesContext);
    const { contacts, getPossibleUserContacts } = useContext(ContactsContext);
    const { getCurrentUser } = useContext(AuthContext);
    const { user } = getCurrentUser();

    // Navigation
    const navigate = useNavigate();

    // State 
    const [tableData, setTableData] = useState([]);
    const [possibleUserContactsIds, setPossibleUserContactsIds] = useState<string[]>(getPossibleUserContacts().map(e => e.id));

    useEffect(() => {
        // console.log("Opportunity", opportunities);
        setTableData(getRows(opportunities));
    }, [opportunities, showClosed]);

    useEffect(() => {
        setPossibleUserContactsIds(getPossibleUserContacts().map(e => e.id));
        setTableData(getRows(opportunities));
    }, [contacts]);

    const clickOverviewHandler = (id: string) => {
        navigate(`/pipeline/opportunities/${id}`);
    };

    const clickContactHandler = (e: any, id: string) => {
        e.stopPropagation();
        navigate(`/people/relationships/${id}`);
    };

    const getRows = (info: ForgeOpportunity[]) => {
        let updatedInfo = info
            .filter((e) => showClosed || !(e.properties.stageDetails?.stage?.metadata?.isClosed ?? false))
            .map((opportunity) => {
                let USDollar = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });

                let decisionMaker = opportunity.getWarmestContact(
                    getPossibleUserContacts(),
                    warmthAlgorithm,
                    user.uid,
                ) ?? opportunity.decisionMaker;
                let opportunityOwner = opportunity.opportunityOwner;

                let color = 'lightgray';
                let score;
                if (possibleUserContactsIds.includes(opportunityOwner?.id) && decisionMaker) {
                    score = getWarmthScore(decisionMaker, warmthAlgorithm, user.uid).get("finalScore");
                    score = isNaN(score) ? 0 : score;
                    color = getColorWithGradient(score);
                } else if (possibleUserContactsIds.includes(decisionMaker?.id) && opportunityOwner) {
                    score = getWarmthScore(opportunityOwner, warmthAlgorithm, user.uid).get("finalScore");
                    score = isNaN(score) ? 0 : score;
                    color = getColorWithGradient(score);
                }

                return {
                    type: "opportunity",
                    id: opportunity.id,
                    name: opportunity.properties?.name,
                    company: opportunity.properties?.company,
                    companyName: opportunity.properties?.company?.name,
                    decisionMakerId: decisionMaker ? decisionMaker.id : undefined,
                    decisionMaker,
                    opportunityOwnerId: opportunityOwner
                        ? opportunityOwner.id
                        : undefined,
                    opportunityOwner,
                    stage: opportunity.properties?.stageDetails?.stage?.label,
                    stageValue: opportunity?.getPercentageFromStageOpportunity(false),
                    amount: USDollar.format(opportunity.properties?.valueAmount),
                    closingDate: opportunity.properties?.closeDate,
                    warmth: score,
                    warmthColor: color,
                    sourceData: opportunity,
                    updatedAt: opportunity.updatedAt,
                };
            });

        return updatedInfo;
    };

    const dataTableData = {
        columns: [
            {
                Header: "opportunity owner",
                width: "50px",
                // widthSetting: "100%",
                padding: { right: 0 },
                paddingHeader: { right: 2 },
                align: "right",
                sorted: true,
                Cell: (info: any) => {
                    return info.cell.row.original.opportunityOwner ? (
                        <Card
                            variant="outlined"
                            style={{
                                boxShadow: "none",
                                border: "1.5px solid black",
                                width: "210px",
                            }}
                            onClick={(e) => clickContactHandler(e, info.cell.row.original.opportunityOwnerId)}
                        >
                            <MDBox display="flex" alignItems="center" m={1}>
                                <ForgeContactAvatar
                                    contact={info.cell.row.original.opportunityOwner}
                                    enableScoreBadge={false}
                                    warmthRingThickness={0}
                                    color="white"
                                    background="black"
                                />
                                <MDBox ml={1} style={{ textAlign: 'start', maxWidth: "70%" }}>
                                    <MDBox
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            color: "black",
                                        }}
                                    >
                                        {info.cell.row.original.opportunityOwner?.name}
                                    </MDBox>
                                    <MDBox
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            color: "gray",
                                        }}>
                                        {info.cell.row.original.opportunityOwner?.jobTitle}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    ) : (
                        <MDBox display="flex" alignItems="center">
                            <Avatar style={{ background: 'lightgray' }}                            >
                                <Person style={{ color: "white" }} />
                            </Avatar>
                            <MDTypography variant="p2" color="text" sx={{ ml: 2, color: 'lightgray' }}>
                                Unassigned
                            </MDTypography>
                        </MDBox>
                    );
                },
            },
            {
                Header: "warmth",
                accessor: "warmth",
                width: "5%",
                widthSetting: "100%",
                sorted: true,
                padding: { left: 0, right: 0 },
                paddingHeader: { left: 0 },
                sortDescFirst: true,
                Cell: (info: any) => {
                    let score = info.cell.row.original.warmth;
                    let color = info.cell.row.original.warmthColor;

                    const [openWarmthDialog, setOpenWarmthDialog] = useState<boolean>(false);
                    const handleClose = () => setOpenWarmthDialog(false);

                    return info.cell.row.original.decisionMakerId && info.cell.row.original.opportunityOwnerId ?
                        (
                            <Stack direction="column" alignItems="center" spacing={1} style={{ position: "relative", width: '100%' }}>
                                {isNaN(score)
                                    ? <Avatar style={{ width: "32px", height: "32px", position: "absolute", zIndex: "1", marginTop: "-12px" }}>
                                        <QuestionMark style={{ color: "white" }} fontSize="medium" />
                                    </Avatar>
                                    : <>
                                        <ContactWarmthDialog
                                            open={openWarmthDialog}
                                            onClose={handleClose}
                                            userContact={info.cell.row.original.opportunityOwner}
                                            contact={info.cell.row.original.decisionMaker}
                                            isOwnUser={possibleUserContactsIds.includes(info.cell.row.original.opportunityOwnerId)}
                                            relationshipScore={score}
                                            relationshipColor={color} />
                                        <Avatar
                                            style={{
                                                width: "32px",
                                                height: "32px",
                                                position: "absolute",
                                                zIndex: "1",
                                                marginTop: "-12px",
                                                background: color,
                                                color: score >= 20 && score < 80 ? 'black' : 'white',
                                                fontSize: "16px",
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setOpenWarmthDialog(true);
                                            }}
                                        >
                                            {score}
                                        </Avatar>
                                    </>
                                }
                                <div style={{
                                    width: "100%",
                                    height: "10px", /* Adjust the height as needed */
                                    background: color,
                                    marginTop: 0,
                                    // borderTop: score >= 20 ? "1px solid black" : null,
                                    // borderBottom: score >= 20 ? "1px solid black" : null,
                                }} ></div >
                            </Stack>
                        )
                        : <></>;
                },
            },
            {
                Header: "key client",
                width: "50px",
                // widthSetting: "100%",
                padding: { left: 0 },
                paddingHeader: { left: 1 },
                sorted: true,
                Cell: (info: any) => {
                    return info.cell.row.original.decisionMakerId ? (
                        <Card
                            variant="outlined"
                            style={{
                                boxShadow: "none",
                                border: "1.5px solid black",
                                width: "200px",
                            }}
                            onClick={(e) => clickContactHandler(e, info.cell.row.original.decisionMakerId)}
                        >
                            <MDBox display="flex" alignItems="center" m={1}>
                                <ForgeContactAvatar
                                    contact={info.cell.row.original.decisionMaker}
                                    enableScoreBadge={false}
                                    warmthRingThickness={0}
                                    color="white"
                                    background="black"
                                />

                                <MDBox ml={1} style={{ maxWidth: "70%" }}>
                                    <MDBox
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            color: "black",
                                        }}
                                        ml={0}>
                                        {info.cell.row.original.decisionMaker?.name}
                                    </MDBox>
                                    <MDBox
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            color: "gray",
                                        }}>
                                        {info.cell.row.original.decisionMaker?.jobTitle}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    ) : (
                        <MDBox display="flex" alignItems="center">
                            <Avatar style={{ background: 'lightgray' }}                            >
                                <Person style={{ color: "white" }} />
                            </Avatar>
                            <MDTypography variant="p2" color="text" sx={{ ml: 2, color: 'lightgray' }}>
                                Unassigned
                            </MDTypography>
                        </MDBox>
                    );
                },
            },
            {
                Header: "account name",
                accessor: "companyName",
                width: "10%",
                sorted: true,
                Cell: (info: any) => {
                    return (
                        <MDBox display="flex" alignItems="center">
                            <Tooltip title={info.cell.row.original.company?.name}>
                                <Avatar
                                    variant="rounded"
                                    alt={info.cell.row.original.company?.name}
                                    src={info.cell.row.original.company?.imageUrl}
                                    style={{ background: info.cell.row.original.company ? 'black' : 'lightgray' }}
                                >
                                    <ApartmentRounded style={{ color: "white" }} />
                                </Avatar>
                            </Tooltip>
                            {info.cell.row.original.company?.name ?
                                <Box sx={{ ml: 2 }}>{info.cell.row.original.company?.name}</Box>
                                : <MDTypography variant="p2" color="text" sx={{ ml: 2, color: 'lightgray' }}>
                                    No Account
                                </MDTypography>}
                        </MDBox>
                    );
                },
            },
            {
                Header: "opportunity name",
                accessor: "name",
                width: "10%",
                sorted: true,
                Cell: (info: any) => {
                    return (
                        <MDBox display="flex" alignItems="center">
                            {info.cell.row.original?.name}
                        </MDBox>
                    );
                },
            },
            {
                Header: "stage",
                accessor: "stageValue",
                width: "10%",
                sorted: true,
                Cell: (info: any) => {
                    return (info.cell.row.original.stage ?
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <MDBox style={{ width: "40px", height: "40px" }}>
                                <Pie
                                    style={{ position: "relative", top: "-4px", marginRight: 4 }}
                                    options={{
                                        animation: {
                                            duration: 0
                                        }
                                    }}
                                    data={{
                                        labels: [],
                                        datasets: [
                                            {
                                                label: [],
                                                weight: 9,
                                                cutout: 0,
                                                tension: 0.9,
                                                pointRadius: 2,
                                                borderWidth: 2,
                                                borderColor: "black",
                                                backgroundColor: ["dark", "white"],
                                                fill: false,
                                                data: [info.cell.row.original.stageValue, 100 - info.cell.row.original.stageValue],
                                            },
                                        ],
                                    }}
                                />
                            </MDBox>
                            {info.cell.row.original.stage}
                        </Stack>
                        : <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <MDBox style={{ width: "40px", height: "40px" }}>
                                <Pie
                                    style={{ position: "relative", top: "-4px", marginRight: 4 }}
                                    options={{
                                        animation: {
                                            duration: 0
                                        }
                                    }}
                                    data={{
                                        labels: [],
                                        datasets: [
                                            {
                                                label: [],
                                                weight: 9,
                                                cutout: 0,
                                                tension: 0.9,
                                                pointRadius: 2,
                                                borderWidth: 1,
                                                borderColor: "lightgray",
                                                backgroundColor: ["lightgray", "white"],
                                                fill: false,
                                                data: [0, 100 - info.cell.row.original.stageValue],
                                            },
                                        ],
                                    }}
                                />
                            </MDBox>
                            <MDTypography variant="p2" color="text" sx={{ color: 'lightgray' }}>
                                No Stage
                            </MDTypography>
                        </Stack>
                    );
                },
            },
            { Header: "amount", accessor: "amount", width: "10%", sorted: true },
            {
                Header: "closing date",
                accessor: "closingDate",
                width: "10%",
                paddingHeader: { right: 2 },
                sorted: true,
                Cell: (info: any) => {
                    return (info.cell.row.original.closingDate ?
                        <Box>{new Date(info.cell.row.original.closingDate).toDateString()}</Box>
                        : <MDTypography variant="p2" color="text" sx={{ color: 'lightgray' }}>
                            No Closing Date
                        </MDTypography>
                    );
                },
            },
            {
                Header: "update date",
                accessor: "updatedAt",
                width: "10%",
                paddingHeader: { right: 2 },
                sorted: true,
                Cell: (info: any) => {
                    return (info.cell.row.original.updatedAt ?
                        <Box>{new Date(info.cell.row.original.updatedAt).toDateString()}</Box>
                        : <MDTypography variant="p2" color="text" sx={{ color: 'lightgray' }}>
                            No Closing Date
                        </MDTypography>
                    );
                },
            },
            // { Header: "next action", accessor: "nextAction", width: "10%" },
        ],

        rows: tableData,
    };

    return (
        <DataTable
            tableId="opportunities"
            table={dataTableData}
            canSearch={true}
            onRowClick={(row, index) => clickOverviewHandler(row.original.id)}
            noEndBorder={true}
        />
    );
}

export default OpportunitiesManagement;