import { ArrowDownwardRounded, Close, Done, Expand, KeyboardArrowDownRounded, KeyboardArrowUpRounded, LocationOn, QuestionMarkRounded, Rsvp, Videocam } from "@mui/icons-material";
import { AppBar, Avatar, Button, Card, Collapse, Icon, IconButton, Stack, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AuthContext, CalendarsContext, setTransparentNavbar, useMaterialUIController } from "context";
import { navbar } from "examples/Navbars/DashboardNavbar/styles";
import EventsApi from "forge/calendar/services/api";
import { set } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { CalendarEvent } from "types/calendar/calendar-event";
import { InvitationStatus, InvitationStatusExtended } from "types/calendar/forge-attendee";
import { VideoConferencingPlatformExtended } from "types/calendar/video-conferencing-platform";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import BackButton from "forge/core/components/BackButton";
import { hasMeetingUrl, hasPhysicalLocation } from "types/forge-event-extension";

const styles = createUseStyles({
    formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px", paddingBottom: "2px" },
});

function EventDetailCard({
    handleOpenDrawer,
    event,
    backSteps = -1
}: {
    handleOpenDrawer: () => void,
    event: CalendarEvent,
    backSteps?: number,
}) {
    // UI
    const classes = styles();

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { getWritableCalendars, isCalendarWritable } = useContext(CalendarsContext);

    // API
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const eventsApi = new EventsApi(user, userProfileData, encryptionService);
    const [loading, setLoading] = useState<boolean>(false);

    // State
    const previousScrollY = useRef(0);
    const [collapseDetail, setCollapseDetail] = useState<boolean>(true);
    const [collapseRsvp, setCollapseRsvp] = useState<boolean>(true);
    const [navbarType, setNavbarType] = useState<
        "fixed" | "absolute" | "relative" | "static" | "sticky"
    >();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;


    const handleOpenDetail = () => setCollapseDetail(!collapseDetail);
    const handleOpenRsvp = () => setCollapseRsvp(!collapseRsvp);

    const renderDate = () => {
        return event?.startDate?.toDate().toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        })
            + " - " +
            event?.endDate?.toDate().toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });

    }

    const renderDescription = (description?: string) => {
        if (!description) return description;

        let hasHTML = /<\/?[a-z][\s\S]*>/i.test(description);
        if (hasHTML) {
            return (<div
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
                style={{ fontSize: "small", textAlign: "left" }}
            />);
        } else {
            return (<MDTypography
                variant="body2"
                fontSize="small"
                sx={{ textAlign: "left" }}
                style={{ whiteSpace: "pre-wrap" }}
            >
                {description}
            </MDTypography>);
        }
    }

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY <= 25) || !fixedNavbar);
        }

        /** 
         The event listener that's calling the handleTransparentNavbar function when 
         scrolling the window.
        */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    // console.log(window.scrollY);

    const getHeight = () => {
        let height = 0;
        if (window.scrollY > 25 && window.scrollY < 95 && previousScrollY.current <= window.scrollY) {
            height = 70;
            if (!transparentNavbar) {

            }
        }

        console.log("scrollY", window.scrollY, "height", height, "transparentNavbar", transparentNavbar)
        if (!transparentNavbar) {
            previousScrollY.current = window.scrollY;
        }
        return height;
    }

    const updateRsvp = async (status: InvitationStatus) => {
        setCollapseRsvp(true);
        setLoading(true);

        event.properties.userAttendee.status = status;
        var calendar = getWritableCalendars().find((e) => e.id == event.calendarId);
        await eventsApi.updateEventInvite(event, calendar);

        setLoading(false);
    }

    const onMeetingAction = () => {
        if (hasPhysicalLocation(event)) {
            window.open(
                `https://www.google.com/maps/search/?api=1&query=${event.properties.location}`,
                "_blank"
            );
        }

        let url = event?.properties?.webUrl ?? event?.properties?.url;

        if (url) {
            window.open(url, "_blank");
        }
    }

    return (
        <AppBar
            position={navbarType}
            color="inherit"
            style={{ padding: 0 }}
            sx={(theme) => navbar(theme, { transparentNavbar })}
        >
            <Card
                id="event-detail"
                style={{ background: transparentNavbar ? "white" : "transparent" }}
            >
                <MDBox mx={3} mt={3} display={"flex"} alignItems={"start"}>
                    <BackButton backSteps={backSteps} />
                    <MDBox flex={1} />
                    <MDBox>
                        {transparentNavbar ? <>
                            <MDTypography
                                variant="h5"
                                fontWeight="medium"
                                sx={{
                                    textAlign: "center",
                                }}
                                style={{
                                    textDecoration: event?.isDeclined ? "line-through" : "none",
                                    color: event?.isDeclined ? "#80808096" : "black"
                                }}
                            >
                                {event?.name}
                            </MDTypography>
                            <MDTypography
                                variant="body2"
                                mb={2}
                                sx={{
                                    textAlign: "center",
                                }}
                                style={{
                                    textDecoration: event?.isDeclined ? "line-through" : "none",
                                    color: event?.isDeclined ? "#80808096" : "black"
                                }}
                            >
                                {renderDate()}
                            </MDTypography>
                        </> :
                            <MDBox height={getHeight()} />
                        }
                        {hasPhysicalLocation(event)
                            ? <MDBox sx={{ textAlign: "center" }}>
                                <MDButton
                                    style={{ color: "black" }}
                                    variant="outlined"
                                    color="dark"
                                    tabIndex={-1}
                                    onClick={onMeetingAction}
                                >
                                    <LocationOn fontSize="large" />
                                    {"Navigate"}
                                </MDButton>
                            </MDBox>
                            : hasMeetingUrl(event)
                                ? <MDBox sx={{ textAlign: "center" }}>
                                    <MDButton
                                        style={{ color: "black" }}
                                        variant="outlined"
                                        color="dark"
                                        tabIndex={-1}
                                        onClick={onMeetingAction}
                                    >
                                        <Stack direction={"row"} alignContent={"center"}>
                                            <img
                                                src={VideoConferencingPlatformExtended.getIcon(event?.properties.videoConferencingPlatform)}
                                                className={classes.formIcon}
                                            />
                                            {"Join Meeting"}
                                        </Stack>
                                    </MDButton>
                                </MDBox>
                                : <MDTypography
                                    variant="body2"
                                    sx={{
                                        textAlign: "center",
                                    }}
                                    mb={2}
                                >
                                    No meeting location found
                                </MDTypography>
                        }
                    </MDBox>
                    <MDBox flex={1} />
                    <Stack
                        direction={"row"}
                        alignSelf={"start"}
                        justifyContent={"right"}
                        alignItems="right"
                        spacing={1}
                    >

                        {event?.isEventEditable(isCalendarWritable) && (loading ? <img src={spinningSphere} style={{ height: "40px" }} alt="Loading..." />
                            : <IconButton size="large" aria-label="edit" color="inherit" onClick={handleOpenRsvp}>
                                <Rsvp
                                    style={{ color: InvitationStatusExtended.color(event?.properties?.userAttendee?.status) }}
                                />
                            </IconButton>
                        )}
                        <Collapse in={!collapseRsvp} orientation="horizontal" style={{ padding: 0 }}>
                            <Stack
                                direction={"row"}
                                alignSelf={"start"}
                                justifyContent={"right"}
                                alignItems="right"
                                spacing={1}
                            >
                                <Avatar style={{ background: InvitationStatusExtended.color(InvitationStatus.accepted), cursor: "pointer" }} onClick={() => updateRsvp(InvitationStatus.accepted)}>
                                    <Done />
                                </Avatar>
                                <Avatar style={{ background: InvitationStatusExtended.color(InvitationStatus.maybe), cursor: "pointer" }} onClick={() => updateRsvp(InvitationStatus.maybe)}>
                                    <QuestionMarkRounded />
                                </Avatar>
                                <Avatar style={{ background: InvitationStatusExtended.color(InvitationStatus.rejected), cursor: "pointer" }} onClick={() => updateRsvp(InvitationStatus.rejected)}>
                                    <Close />
                                </Avatar>
                            </Stack>
                        </Collapse>
                        <IconButton size="small" aria-label="edit" color="inherit" onClick={event?.properties.description?.trim().length > 0 ? handleOpenDetail : undefined}>
                            {collapseDetail
                                ? <KeyboardArrowDownRounded
                                    fontSize="medium"
                                    style={{ color: event?.properties.description?.trim().length > 0 ? undefined : "lightgray" }}
                                />
                                : <KeyboardArrowUpRounded fontSize="medium" />}
                        </IconButton>
                        {event?.isEventEditable(isCalendarWritable) && <IconButton size="small" aria-label="edit" color="inherit" onClick={handleOpenDrawer}>
                            <Icon fontSize="small">edit</Icon>
                        </IconButton>}
                    </Stack>
                </MDBox>
                <MDBox mx={5} my={1.5} >
                    <Collapse in={!collapseDetail} style={{ padding: 0 }}>
                        {renderDescription(event?.properties?.description)}
                    </Collapse>
                </MDBox>
            </Card>
        </AppBar>
    );
}

// Setting default values for the props of EventDetailCard
EventDetailCard.defaultProps = {
    handleCloseDrawer: null,
};

export default EventDetailCard;
