import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { AuthContext, CalendarsContext, ContactsContext } from "context";

// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    Chip,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    createFilterOptions,
    useMediaQuery,
} from "@mui/material";
import {
    Abc,
    CalendarMonth,
    Close,
    EditNoteOutlined,
    ExpandMore,
    LocationOnOutlined,
    Notifications,
    Public,
    Replay,
    Schedule,
    VideocamOutlined,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import calendarColors, { CalendarType, CalendarTypeExtended, ForgeCalendar, hexToColorInt, intToColor } from "types/calendar/calendar";
import MDButton from "components/MDButton";
import dayjs, { Dayjs } from "dayjs";

// Calendar Logos
import PlacesAutocomplete from "../../../core/components/PlacesAutocomplete";
import { VideoConferencingPlatform, VideoConferencingPlatformExtended } from "types/calendar/video-conferencing-platform";
import { CalendarEvent } from "types/calendar/calendar-event";
import { CalendarEventProperties } from "types/calendar/calendar-event-properties";
import { ForgeRecurrence } from "types/calendar/forge-recurrence";
import { AttendeeType, ForgeAttendee, InvitationStatus } from "types/calendar/forge-attendee";

// Services
import EventsApi from "../../services/api";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { decimalToTime, getLocalTimezone, getTimeZone, timezoneIds } from "types/timezones";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { isOrganizer } from "types/forge-event-extension";
import theme from "assets/theme";
import { AttendeesSelector } from "../AttendeesSelector";
import { roundToNearestXDuration } from "forge/calendar/utils";

const styles = createUseStyles({
    formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
    formColor: { alignSelf: "center", height: "1.5em", width: "1.5em" },
    formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
    root: {
        "& .MuiFormLabel-root": {
            paddingTop: "4px"
        }
    },
    accordionRoot: {
        '&:before': {
            display: 'none',
        },
    },
});

function CreateEventDrawer({
    openDrawer,
    handleCloseDrawer,
    event,
    opportunityId,
    initialStartDate,
    initialEndDate,
}: {
    openDrawer: boolean,
    handleCloseDrawer: () => void,
    event?: CalendarEvent,
    opportunityId?: string,
    initialStartDate?: dayjs.Dayjs,
    initialEndDate?: dayjs.Dayjs,
}) {
    // UI
    const classes = styles();
    const navigate = useNavigate();
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { calendars, getWritableCalendars } = useContext(CalendarsContext);

    // API
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const eventsApi = new EventsApi(user, userProfileData, encryptionService);
    const [loading, setLoading] = useState<boolean>(false);

    // Meet Name
    const [name, setName] = useState<string>("");

    // Dates
    const [allDay, setAllDay] = useState<boolean>(false);
    const now = new Date();
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(roundToNearestXDuration(now, 5 * 60 * 1000)));
    now.setMinutes(now.getMinutes() + 30);
    const [endDate, setEndDate] = useState<Dayjs>(dayjs(roundToNearestXDuration(now, 5 * 60 * 1000)));
    const [timezone, setTimeZone] = useState<string>(getLocalTimezone());
    const [minutesDifference, setMinutesDifference] = useState<number>(30);
    const handleSetStartDate = (value: dayjs.Dayjs) => {
        setStartDate(value);
        setEndDate(value.add(minutesDifference, "minutes"));
    }
    const handleSetEndDate = (value: dayjs.Dayjs) => {
        setEndDate(value);
        setMinutesDifference(value.diff(startDate, 'minutes'));
    }

    // Extra Options Switch
    const [showExtraOptions, setShowExtraOptions] = useState<boolean>(false);

    // Recurrence
    const [doesRepeat, setDoesRepeat] = useState<boolean>(false);
    const [recurrence, setRecurrence] = useState<string>('daily');
    const [recurrenceEnd, setRecurrenceEnd] = useState<string>('noEnd');
    const [onADate, setOnADate] = useState<Dayjs>(startDate);
    const [xOccurrences, setXOccurrences] = useState<number>(1);

    // Calendar
    const [writableCalendars, setWritableCalendars] = useState<ForgeCalendar[]>();
    const [calendar, setCalendar] = useState<ForgeCalendar>(() => {
        let calendars = getWritableCalendars();
        if (calendars.length > 0) {
            const nonLocalCalendars = calendars.filter((e) => e.type !== "CalendarType.local");

            if (nonLocalCalendars.length > 0) {
                return nonLocalCalendars.find((e) => e.lastUsed) ?? nonLocalCalendars[0];
            }
        }

        return null;
    });

    // Attendees
    const [attendees, setAttendees] = useState<ForgeAttendee[]>([]);
    const [attendeesResearch, setAttendeesResearch] = useState<ForgeAttendee[]>([]);

    // Meet
    const [activeMeet, setActiveMeet] = useState<boolean>(false);
    const [platform, setPlatform] = useState<VideoConferencingPlatform | null>();
    const [meetUrl, setMeetUrl] = useState<string | null>();
    const [zoomUrl, setZoomUrl] = useState<string | null>(userProfileData?.zoomUrl);

    // Location
    const [location, setLocation] = useState<string | null>();

    // Color
    const [allColors, setAllColors] = useState<{ [key: string]: string; }>(calendarColors);
    const [color, setColor] = useState<string | null>("Cyan");

    // Notification
    const [notification, setNotification] = useState<number>(5);

    // Description
    const [description, setDescription] = useState<string | null>();

    // Track initial state values
    const initialState = useRef<{ [key: string]: any }>({
        name,
        allDay,
        startDate,
        endDate,
        // timezone,
        doesRepeat,
        recurrence,
        recurrenceEnd,
        onADate,
        xOccurrences,
        calendar,
        attendees,
        attendeesResearch,
        activeMeet,
        platform,
        meetUrl,
        zoomUrl,
        location,
        notification,
        description
    });

    const [openDialogInvites, setOpenDialogInvites] = useState(false);
    const [invitesDialogTitle, setInvitesDialogTitle] = useState<string>();
    const [invitesDialogDescription, setInvitesDialogDescription] = useState<string>();
    const handleOpenDialogInvites = () => setOpenDialogInvites(true);
    const [openDialogDiscard, setOpenDialogDiscard] = useState(false);
    const handleOpenDialogDiscard = () => setOpenDialogDiscard(true);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const handleOpenDialogDelete = () => setOpenDialogDelete(true);

    const enableOrganizerFeatures = useMemo(() => {
        return !event || isOrganizer(event);
    }, []);

    const handleClose = () => {
        const currentState: { [key: string]: any } = {
            name,
            allDay,
            startDate,
            endDate,
            // timezone,
            doesRepeat,
            recurrence,
            recurrenceEnd,
            onADate,
            xOccurrences,
            calendar,
            attendees,
            attendeesResearch,
            activeMeet,
            platform,
            meetUrl,
            zoomUrl,
            location,
            notification,
            description
        };

        // Compare current state with initial state
        const hasChanged = Object.keys(currentState).some(
            key => {
                if (dayjs.isDayjs(currentState[key]) && dayjs.isDayjs(initialState.current[key])) {
                    return !currentState[key].isSame(initialState.current[key]);
                }

                return currentState[key] !== initialState.current[key];
            }
        );

        if (hasChanged && !loading) {
            console.log('State has changed');
            handleOpenDialogDiscard();
        } else {
            handleCloseDrawer();
        }
    }

    useEffect(() => {
        (async () => {
            let finalTimezone = await getTimeZone();
            setTimeZone(finalTimezone);
        })()

        if (!event) return;

        // Meet Name
        setName(event.name);

        // Dates
        let startDate = dayjs(event.startDate.toDate());
        let endDate = dayjs(event.endDate.toDate());
        setStartDate(startDate);
        setEndDate(endDate);
        setMinutesDifference(endDate.diff(startDate, "minutes"));

        // Recurrence
        setShowExtraOptions(event.recurrence.recurrent);
        setDoesRepeat(event.recurrence.recurrent);
        setRecurrence(event.recurrence.frequency ?? 'daily');
        let date = event.recurrence.onADate;
        let occurrences = event.recurrence.xOccurrences;
        setRecurrenceEnd(date != null ? 'date' : occurrences != null ? "occurrences" : "noEnd");
        setOnADate(dayjs(date ?? startDate));
        setXOccurrences(occurrences ?? 1);

        // Calendar
        let calendars = getWritableCalendars();
        let calendar: ForgeCalendar;
        if (calendars.length > 0) {
            calendar = calendars.find((e) => e.id === event.calendarId);
            setCalendar(calendar);
            if (calendar?.color) {
                setAllColors((previousState) => ({
                    ...previousState,
                    ...{ "Default calendar color": intToColor(calendar.color) }
                }));
                setColor("Default calendar color");
            }
        }

        // Attendees
        setAttendees(event.properties.attendees);
        setAttendeesResearch(event.properties.attendeesResearch);

        // Meet
        setActiveMeet(event.properties.url != null);
        setPlatform(VideoConferencingPlatformExtended.fromUrlType(event.properties.urlType));
        setMeetUrl(event.properties.webUrl);

        // Location
        setLocation(event.properties.location);

        // Description
        setDescription(event.properties.description);

        initialState.current = {
            name: event.name,
            allDay,
            startDate,
            endDate,
            // timezone,
            doesRepeat,
            recurrence: event.recurrence.frequency ?? 'daily',
            recurrenceEnd: date != null ? 'date' : occurrences != null ? "occurrences" : "noEnd",
            onADate: dayjs(date ?? startDate),
            xOccurrences: occurrences ?? 1,
            calendar,
            attendees: event.properties.attendees,
            attendeesResearch: event.properties.attendeesResearch,
            activeMeet: event.properties.url != null,
            platform: VideoConferencingPlatformExtended.fromUrlType(event.properties.urlType),
            meetUrl: event.properties.webUrl,
            zoomUrl,
            location: event.properties.location,
            notification,
            description: event.properties.description
        };
    }, [event]);

    useEffect(() => {
        setStartDate(initialStartDate);
    }, [initialStartDate]);

    useEffect(() => {
        setEndDate(initialEndDate);
    }, [initialEndDate]);

    useEffect(() => {
        if (calendar?.color) {
            setAllColors((previousState) => ({
                ...previousState,
                ...{ "Default calendar color": intToColor(calendar.color) }
            }));
            setColor("Default calendar color");
        }
    }, [calendar]);

    useEffect(() => {
        let calendars = getWritableCalendars();
        setWritableCalendars(calendars);

        if (event) {
            let calendar: ForgeCalendar;
            if (calendars.length > 0) {
                calendar = calendars.find((e) => e.id === event.calendarId);
                setCalendar(calendar);
                if (calendar?.color) {
                    setAllColors((previousState) => ({
                        ...previousState,
                        ...{ "Default calendar color": intToColor(calendar.color) }
                    }));
                    setColor("Default calendar color");
                }
            }
        }
    }, [calendars]);

    const handleCloseDialogInvites = async (result: boolean) => {
        setOpenDialogInvites(false);
        if (result) {
            handleSubmit();
        }
    };

    const handleSave = async (e?: any) => {
        e?.preventDefault();

        if (attendees.length > 0) {
            setInvitesDialogTitle("Email Invites");
            setInvitesDialogDescription(`These people will receive an invite:\n${attendees.map((e) => e.emailAddress).join('\n')}`);
        } else if (attendeesResearch.length > 0) {
            setInvitesDialogTitle("Email Invites");
            setInvitesDialogDescription(`No email invites will be sent, all attendees are research attendees.`);
        } else {
            handleSubmit();
            return;
        }
        setOpenDialogInvites(true);
    }

    const handleSubmit = async () => {
        setLoading(true);

        let finalStartDate = startDate.toDate();
        let finalEndDate = endDate.toDate();

        if (allDay &&
            (calendar.type == CalendarType.google || calendar.type == CalendarType.outlook)) {
            finalEndDate.setDate(finalEndDate.getDate() + 1);
            finalEndDate.setHours(0, 0, 0, 0);
        }

        let newEvent = new CalendarEvent(
            {
                eventId: 'forgeEventCreation',
                name: name,
                startDate: finalStartDate,
                endDate: finalEndDate,
                properties: new CalendarEventProperties(
                    {
                        location: location,
                        description: description,
                        attendees: attendees,
                        attendeesRefs: attendees.map((e) => e.contact?.ref).filter((e) => e),
                        attendeesResearch: attendeesResearch,
                        attendeesResearchRefs: attendeesResearch.map((e) => e.contact?.ref ?? e.contact?.contact?.ref).filter((e) => e),
                        url: meetUrl ?? platform === VideoConferencingPlatform.zoom ? zoomUrl : null,
                        urlType: activeMeet
                            ? VideoConferencingPlatformExtended.getUrlType(platform)
                            : null,
                    }
                ),
                reminder: notification,
                recurrence: new ForgeRecurrence(
                    {
                        recurrent: doesRepeat,
                        googleRecurrence: doesRepeat && CalendarTypeExtended.getRecurrenceRule(
                            CalendarType.google,
                            finalStartDate,
                            finalEndDate,
                            recurrence,
                            recurrenceEnd === 'date' && onADate.toDate(),
                            recurrenceEnd === 'occurrences' && xOccurrences,
                        ),
                        outlookRecurrence: doesRepeat && CalendarTypeExtended.getRecurrenceRule(
                            CalendarType.outlook,
                            finalStartDate,
                            finalEndDate,
                            recurrence,
                            recurrenceEnd === 'date' && onADate.toDate(),
                            recurrenceEnd === 'occurrences' && xOccurrences,
                        ),
                    }
                ),
                calendarType: calendar?.type,
                calendarId: calendar.id ?? '',
                colorValue: hexToColorInt(color) ?? calendar.color ?? 4282218990,
                opportunityId: opportunityId,
            }
        );

        if (event) {
            newEvent.eventId = event.eventId;
            console.log(newEvent);
            await eventsApi.updateEvent(newEvent, calendar, activeMeet, timezone);
        } else {
            await eventsApi.createEvent(newEvent, calendar, activeMeet, timezone);
        }
        setLoading(false);
        handleCloseDrawer();
    };

    const onDeleteTapped = () => {
        handleOpenDialogDelete();
    }

    const handleCloseDialogDiscard = async (result: boolean) => {
        setOpenDialogDiscard(false);
        if (result) {
            handleCloseDrawer();
        }
    };

    const handleCloseDialogDelete = async (result: boolean, deleteRecurrence = false) => {
        setOpenDialogDelete(false);
        if (result) {
            setLoading(true);
            let result = await eventsApi.deleteEvent(event, calendar, deleteRecurrence);
            if (result) {
                handleCloseDrawer();
                navigate("/home");
            }
        }
    };

    const buildTimeChips = (
        <MDBox className={classes.formTile}>
            <MDBox className={classes.formIcon} />
            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={0.5} style={{ marginLeft: "auto" }}>
                {[
                    {
                        "label": "15min",
                        "value": 15
                    },
                    {
                        "label": "30min",
                        "value": 30
                    },
                    {
                        "label": "45min",
                        "value": 45
                    },
                    {
                        "label": "60min",
                        "value": 60
                    },
                    {
                        "label": "2hrs",
                        "value": 120
                    }
                ].map(({ label, value }) => (
                    <Chip
                        label={label}
                        onClick={() => {
                            handleSetEndDate(startDate.add(value, "m"));
                        }}
                    />
                ))}
            </Stack>
        </MDBox>
    );

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    height: "fit-content",
                    maxHeight: "96%",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            <FormControl component="form" style={{ display: "contents" }}>
                <ConfirmationDialog
                    openDialog={openDialogInvites}
                    handleCloseDialog={handleCloseDialogInvites}
                    title={invitesDialogTitle}
                    description={invitesDialogDescription?.split('\n').map((line, index) => (
                        <Fragment key={index}>
                            {line}
                            <br />
                        </Fragment>
                    ))}
                    confirmText="Save"
                    denyVariant="outlined"
                />
                <ConfirmationDialog
                    openDialog={openDialogDiscard}
                    handleCloseDialog={handleCloseDialogDiscard}
                    title="Are you sure you want to discard this event?"
                    description=""
                    confirmText="Discard"
                    denyVariant="outlined"
                />
                <ConfirmationDialog
                    openDialog={openDialogDelete}
                    handleCloseDialog={handleCloseDialogDelete}
                    title="Are you sure you want to delete this event?"
                    description="This is a permanent action."
                    confirmText="Delete"
                    confirmColor="error"
                    confirmComponent={
                        // <ButtonGroup
                        //     orientation="vertical"
                        //     aria-label="Recurrent Event Delete Actions"
                        //     variant="contained"
                        //     style={{ marginLeft: "8px" }}
                        // >
                        //     <Button key="single" style={{ color: "white", textTransform: "none", fontSize: "medium" }}>This event only</Button>
                        //     <Button key="all" style={{ color: "white", textTransform: "none", fontSize: "medium" }}>All events</Button>
                        // </ButtonGroup>
                        event?.recurrence?.recurrent
                            ? <Stack direction={"row"} spacing={1} style={{ marginLeft: "8px" }}>
                                <MDButton onClick={() => handleCloseDialogDelete(true)} color={"error"}>This event only</MDButton>
                                <MDButton onClick={() => handleCloseDialogDelete(true, true)} color={"error"}>All events</MDButton>
                            </Stack>
                            : undefined
                    }
                />
                <Backdrop
                    sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                    open={loading}
                >
                    <Stack direction="column" spacing={1}>
                        <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
                        <MDTypography variant="h6" textAlign="center">Syncing with Calendar...</MDTypography>
                    </Stack>
                </Backdrop>

                {/* Header */}
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                    pt={4}
                    pb={0.5}
                    px={3}
                >
                    <MDBox>
                        <MDTypography variant="h5">{event ? 'Edit' : 'Create an'} Event</MDTypography>
                    </MDBox>

                    <Icon
                        sx={({ typography: { size }, palette: { dark, white } }) => ({
                            fontSize: `${size.lg} !important`,
                            color: dark.main,
                            stroke: "currentColor",
                            strokeWidth: "2px",
                            cursor: "pointer",
                            transform: "translateY(5px)",
                        })}
                        onClick={handleClose}
                    >
                        close
                    </Icon>
                </MDBox>
                <Divider />

                {/* Body */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MDBox px={3} style={{ width: "100%", flex: 1 }}>
                        <MDBox className={classes.formTile}>
                            <Abc className={classes.formIcon} />
                            <TextField
                                id="title"
                                label="Add title"
                                variant="outlined"
                                style={{ flex: 1 }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                InputProps={{
                                    readOnly: enableOrganizerFeatures ? false : true,
                                }}
                            />
                        </MDBox>
                        <Divider />
                        {enableOrganizerFeatures && <>
                            <MDBox className={classes.formTile}>
                                <Schedule className={classes.formIcon} />
                                <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                    All Day
                                </MDTypography>
                                <Box style={{ flex: 1 }} />
                                <Switch
                                    checked={allDay}
                                    onChange={(e) => setAllDay(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </MDBox>
                            <MDBox className={classes.formTile}>
                                <MDBox className={classes.formIcon} />
                                <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                    Starts
                                </MDTypography>
                                <Box style={{ flex: 1 }} />
                                <DatePicker
                                    format="MMM DD, YYYY"
                                    value={startDate}
                                    onChange={handleSetStartDate}
                                />
                                {!allDay && <TimePicker
                                    value={startDate}
                                    onChange={handleSetStartDate}
                                    closeOnSelect={false}
                                />}
                            </MDBox>
                            {buildTimeChips}
                            <MDBox className={classes.formTile}>
                                <MDBox className={classes.formIcon} />
                                <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                    Ends
                                </MDTypography>
                                <Box style={{ flex: 1 }} />
                                <DatePicker
                                    format="MMM DD, YYYY"
                                    value={endDate}
                                    minDate={startDate}
                                    onChange={handleSetEndDate}
                                />
                                {!allDay && <TimePicker
                                    value={endDate}
                                    minTime={startDate}
                                    onChange={handleSetEndDate}
                                    closeOnSelect={false}
                                />}
                            </MDBox>

                            <Accordion
                                expanded={showExtraOptions}
                                onChange={() => setShowExtraOptions(!showExtraOptions)}
                                style={{ boxShadow: "none", flex: 1, overflow: "auto" }}
                                className={classes.accordionRoot}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ paddingLeft: "48px" }}
                                >
                                    <MDTypography
                                        sx={{ "white-space": "break-spaces" }}
                                        variant="body2"
                                        fontWeight="small">
                                        More Options
                                    </MDTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                    <MDBox className={classes.formTile}>
                                        <Public className={classes.formIcon} />
                                        <Autocomplete
                                            id="timezone-select"
                                            sx={{ width: 300 }}
                                            style={{ flex: 1 }}
                                            value={timezone}
                                            options={Object.keys(timezoneIds)}
                                            autoHighlight
                                            getOptionLabel={(option) => option}
                                            onChange={(event: any, newValue: string) => { setTimeZone(newValue) }}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                    {...props}
                                                >
                                                    {`${option.replaceAll('_', ' ')} (UTC${decimalToTime(dayjs(new Date()).tz(option, true).utcOffset() / 60)})`}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.root}
                                                    label="Timezone"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </MDBox>
                                    <MDBox className={classes.formTile}>
                                        <Replay className={classes.formIcon} />
                                        <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                            Repeat
                                        </MDTypography>
                                        <Box style={{ flex: 1 }} />
                                        <Switch
                                            value={doesRepeat}
                                            checked={doesRepeat}
                                            onChange={(e) => setDoesRepeat(e.target.checked)}
                                        />
                                    </MDBox>
                                    {doesRepeat && <Stack direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={1}>
                                        {["Daily", "Weekly", "Monthly", "Yearly"].map((e: string) => (
                                            <Chip
                                                label={e}
                                                style={{
                                                    margin: "4px",
                                                    background: e.toLowerCase() == recurrence ? "black" : null,
                                                    color: e.toLowerCase() == recurrence ? "white" : null,
                                                }}
                                                onClick={() => setRecurrence(e.toLowerCase())}
                                            />
                                        ))}
                                    </Stack>}
                                    {doesRepeat && <MDBox>
                                        <MDBox className={classes.formTile}>
                                            <MDBox className={classes.formIcon} />
                                            <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                                Ends
                                            </MDTypography>
                                        </MDBox>
                                        <Stack direction="row" alignSelf={"center"} justifyContent={"space-between"} alignItems="space-between" spacing={1}>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={recurrenceEnd}
                                                onChange={(_, value) => {
                                                    return setRecurrenceEnd(value);
                                                }}
                                                style={{ marginLeft: "48px" }}
                                            >
                                                <FormControlLabel
                                                    value="noEnd"
                                                    control={<Radio />}
                                                    label={<MDTypography variant="body2" style={{ alignSelf: "center" }}>Never</MDTypography>}
                                                    style={{ marginBottom: 12 }}
                                                />
                                                <FormControlLabel
                                                    value="date"
                                                    control={<Radio />}
                                                    label={<MDTypography variant="body2" style={{ alignSelf: "center" }}>On this date:</MDTypography>}
                                                    style={{ marginBottom: 12 }}
                                                />
                                                <FormControlLabel
                                                    value="occurrences"
                                                    control={<Radio />}
                                                    label={<MDTypography variant="body2" style={{ alignSelf: "center" }}>After this many occurrences:</MDTypography>}
                                                />
                                            </RadioGroup>
                                            <Stack direction="column" spacing={1}>
                                                <MDBox height={40} />
                                                <DatePicker
                                                    format="MMM DD, YYYY"
                                                    value={onADate}
                                                    minDate={startDate}
                                                    onChange={setOnADate}
                                                    readOnly={recurrenceEnd !== "date"}
                                                />
                                                <TextField
                                                    id="outlined-number"
                                                    label="Occurrences"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{ readOnly: recurrenceEnd !== "occurrences" }}
                                                    value={xOccurrences}
                                                    onChange={(e) => setXOccurrences(parseInt(e.target.value))}
                                                />
                                            </Stack>
                                        </Stack>
                                    </MDBox>}
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </>}
                        {enableOrganizerFeatures && <>
                            <MDBox className={classes.formTile}>
                                <CalendarMonth className={classes.formIcon} />
                                <Autocomplete
                                    id="calendar-select"
                                    sx={{ width: 300 }}
                                    style={{ flex: 1 }}
                                    options={writableCalendars}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={calendar}
                                    onChange={(event: any, newValue: ForgeCalendar) => setCalendar(newValue)}
                                    getOptionDisabled={(option) => option.type === "CalendarType.local"}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={CalendarTypeExtended.getIcon(option.type)}
                                                alt=""
                                            />
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.root}
                                            label="Choose a calendar"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </MDBox>
                            <Divider />
                        </>}
                        <AttendeesSelector
                            attendees={attendees}
                            setAttendees={setAttendees}
                            attendeesResearch={attendeesResearch}
                            setAttendeesResearch={setAttendeesResearch}
                        />
                        <Divider />
                        {enableOrganizerFeatures && <> {activeMeet ?
                            <MDBox style={{ display: "flex", alignItems: "start", marginBottom: "12px" }}>
                                <img src={VideoConferencingPlatformExtended.getIcon(platform)} style={{ height: "1.5em", width: "1.5em", marginRight: "16px", marginTop: "8px" }} />
                                <Stack
                                    direction="column"
                                    alignItems="left"
                                    style={{ width: "100%" }}
                                >
                                    <MDTypography variant="h6" style={{ fontWeight: "normal" }}>{VideoConferencingPlatformExtended.toString(platform)}</MDTypography>
                                    <MDTypography variant="body2" style={{ color: "gray", lineHeight: "1rem" }}>Video conferencing and dial-in details added</MDTypography>
                                    {platform === VideoConferencingPlatform.zoom && <TextField
                                        id="zoomUrl"
                                        placeholder="Zoom Url"
                                        variant="outlined"
                                        style={{ flex: 1, marginTop: 8 }}
                                        value={zoomUrl}
                                        onChange={(e) => setZoomUrl(e.target.value)}
                                        InputProps={{
                                            readOnly: enableOrganizerFeatures ? false : true,
                                        }}
                                    />}
                                </Stack>
                                <Box style={{ flex: 1 }} />
                                <IconButton onClick={() => {
                                    setActiveMeet(false);
                                    setPlatform(null);
                                }}>
                                    <Close />
                                </IconButton>
                            </MDBox>
                            : <MDBox className={classes.formTile}>
                                <VideocamOutlined className={classes.formIcon} />
                                <Box style={{ flex: 1 }} />
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={6}
                                >
                                    {calendar?.availablePlatforms().map((platform) =>
                                        <img
                                            src={VideoConferencingPlatformExtended.getIcon(platform)}
                                            style={{ width: "56px", cursor: "pointer" }}
                                            onClick={() => {
                                                setPlatform(activeMeet ? null : platform);
                                                setActiveMeet(!activeMeet);
                                            }}
                                        />
                                    )}
                                </Stack>
                                <Box style={{ flex: 1 }} />
                            </MDBox>}
                            <Divider />
                            <MDBox className={classes.formTile}>
                                <LocationOnOutlined className={classes.formIcon} />
                                <PlacesAutocomplete
                                    onSelection={(place) => setLocation(place.structured_formatting.main_text)}
                                    initialValue={location ? {
                                        description: location,
                                        structured_formatting: {
                                            main_text: location,
                                            secondary_text: ''
                                        }
                                    } : null}
                                />
                            </MDBox>
                            <Divider />
                        </>}
                        <MDBox className={classes.formTile}>
                            <MDBox className={classes.formColor} mr={3.25} ml={0.75} style={{ width: 16, height: 16, background: allColors[color], borderRadius: 4 }} />
                            <Autocomplete
                                id="color-select"
                                sx={{ width: 300 }}
                                style={{ flex: 1 }}
                                autoHighlight
                                options={Object.keys(allColors)}
                                value={color}
                                getOptionLabel={(option) => option}
                                onChange={(event: any, newValue: string) => setColor(newValue)}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        <MDBox mr={1.5} style={{ width: 16, height: 16, background: allColors[option], borderRadius: 4 }} />
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.root}
                                        label="Event Color"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </MDBox>
                        <Divider />
                        <MDBox className={classes.formTile}>
                            <Notifications className={classes.formIcon} />
                            <Autocomplete
                                id="notification-select"
                                sx={{ width: 300 }}
                                style={{ flex: 1 }}
                                autoHighlight
                                options={[0, 5, 10, 15, 20, 30, 60]}
                                value={notification}
                                getOptionLabel={(option) => option == 0 ? 'None' : option == 60 ? '1 hour before' : `${option} minutes before`}
                                onChange={(event: any, newValue: number) => setNotification(newValue)}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        {option == 0 ? 'None' : option == 60 ? '1 hour before' : `${option} minutes before`}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.root}
                                        label="Notification"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </MDBox>
                        {enableOrganizerFeatures && <>
                            <Divider />
                            <MDBox className={classes.formTile}>
                                <EditNoteOutlined className={classes.formIcon} />
                                <TextField
                                    id="title"
                                    label="Description"
                                    variant="outlined"
                                    style={{ flex: 1 }}
                                    multiline
                                    maxRows={5}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </MDBox>
                        </>}
                    </MDBox>
                </LocalizationProvider>

                {/* Footer */}
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        p: 1.5,
                        pb: 2,
                        borderTop: "1px solid",
                        borderColor: "divider",
                        justifyContent: "space-between",
                    }}
                >
                    <MDButton variant="text" color="dark" onClick={onDeleteTapped}>
                        {event ? 'Delete' : 'Clear'}
                    </MDButton>
                    <MDButton variant="gradient" color="info" onClick={handleSave}>
                        {event ? 'Edit' : 'Create'} Event
                    </MDButton>
                </Box>
            </FormControl>
        </Drawer>
    );
}

// Setting default values for the props of CreateEventDrawer
CreateEventDrawer.defaultProps = {
    handleCloseDrawer: null,
};

export default CreateEventDrawer;