import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";
import ContactsFirestore from "./firestore";
import ContactsApi from "./api";
import { Crm, CrmExtended } from "types/pipeline/crm";

interface ContactContextType {
    crm: Crm;
    crmContact: any;
    refreshLinkedInData: (linkedInUrl: string) => Promise<any>;
    searchLinkedInProfiles: () => Promise<any>;
}

export const ContactContext = createContext<ContactContextType>({
    crm: undefined,
    crmContact: null,
    refreshLinkedInData: async () => { },
    searchLinkedInProfiles: async () => { },
});

export const ContactContextProvider = ({ contact, children }: { contact: any, children: any }) => {
    // Context
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsFirestoreService = new ContactsFirestore(user, userProfileData, encryptionService);
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // State
    const [crm, setCrm] = useState<any>();
    const [crmContact, setCrmContact] = useState<any>();

    useEffect(() => {
        if (userProfileData?.pipelineUserConfig?.isEnabled) {
            (async () => {
                const possibleCrmContacts = await contactsApi.searchCrmContact(contact);
                setCrmContact(possibleCrmContacts?.find((option: any) => option.crmId == contact.crmId));
            })()
        }
    }, [contact?.ref, userProfileData?.pipelineUserConfig?.integration]);

    useEffect(() => {
        if (userProfileData) {
            setCrm(CrmExtended.crmFromIntegration(userProfileData.pipelineUserConfig.integration));
        }
    }, [userProfileData?.pipelineUserConfig?.integration]);

    const refreshLinkedInData = async (linkedInUrl: string) => {
        await contactsApi.refreshLinkedInData(linkedInUrl, contact);
    }

    const searchLinkedInProfiles = async () => {
        await contactsApi.searchLinkedInProfiles(contact);
    }

    return (
        <ContactContext.Provider
            value={{
                crmContact,
                crm,
                refreshLinkedInData,
                searchLinkedInProfiles,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};