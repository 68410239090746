import { LinkedIn } from "@mui/icons-material";
import { Avatar, Card, Dialog, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";

const styles = createUseStyles({
    lightGray: { color: "darkgray" },
});

function LinkedInOptionCard({
    linkedInOption,
    onClick,
}: {
    linkedInOption: LinkedInProfileOption,
    onClick: () => void
}) {
    // UI
    const classes = styles();

    // State
    const [openProfilePicture, setOpenProfilePicture] = useState(false);

    const onLinkedInLogoTapped = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(linkedInOption.link, "_blank");
    }

    const onProfilePictureTapped = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenProfilePicture(true);
    }

    return (
        <Card style={{ marginBottom: 16, cursor: "pointer" }} onClick={onClick}>
            {linkedInOption.profilePicture && <Dialog
                open={openProfilePicture}
                onClose={() => setOpenProfilePicture(false)}
                PaperProps={{
                    style: {
                        color: 'white',
                        backgroundColor: 'black',
                        boxShadow: 'none',
                    },
                    sx: { borderRadius: "16px" },
                }}
            >
                <img
                    src={linkedInOption.profilePicture}
                    alt={linkedInOption.fullName}
                />
            </Dialog>}
            <Stack direction="row" alignItems="center" spacing={0.5} style={{ margin: 8 }}>
                <Avatar
                    src={linkedInOption.profilePicture}
                    style={{ backgroundColor: "black", fontSize: "1rem" }}
                    onClick={onProfilePictureTapped}
                >
                    {linkedInOption.initials}
                </Avatar>
                <Stack direction="column" alignItems="start" >
                    <MDTypography variant="body2" ml={1} >
                        {linkedInOption.fullName}
                    </MDTypography>
                    <MDTypography variant="body2" fontSize="small" ml={1} className={classes.lightGray} >
                        {linkedInOption.cleanTitle}
                    </MDTypography>
                </Stack>
                <MDBox flex={1} />
                <LinkedIn fontSize="large" onClick={onLinkedInLogoTapped} />
            </Stack>
        </Card>
    );
};

export default LinkedInOptionCard;